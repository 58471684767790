//智能机浏览器版本信息:
let browser = {
    versions: function () {
        let u = navigator.userAgent, app = navigator.appVersion;
        return {
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
            iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部
        };
    }(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase()
}
let browser2 = {
    versions: function () {
        let u = navigator.userAgent.toLowerCase(), app = navigator.appVersion;
        return {
            weixin: u.indexOf("micromessenger") > -1 //opera内核
        };
    }(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase()
}
if (browser.versions.android || browser.versions.iPhone || browser.versions.ios || browser.versions.iPad) {
    window.location.href = "/mobile";
} else {
    if (browser2.versions.weixin) {
        window.location.href = "/weixin";
    }
}
