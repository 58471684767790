import ggwpby from "@/router/ggwpby";

const state = () => ({
    expKey: "ggwpby",
    config: {
        //全屏实验
        openFullState: false,
        //打印日志
        logState: false,
        //自动测试
        autoClickState: true,
        //自动点击时间（秒）
        autoClickTime: 3,

        //基础报酬
        baseMoney: 10,
        //一元等于多少代币
        tokenMoney: 25
    },
    ggwpbyConfigList: [],
    // 结果数据
    ggwpbyResult: {},
    // 房间信息
    ggwpbyRoom: {},
    // 实验的策略的配置参数
    ggwpbyErtRuleParamJsonDto: {},
    // 裁判数据
    ggwpbyRefereeDto: {},
    // 每轮次随机数值【可以遗弃】
    ggwpbyRandomDto: {},
    // 结果详情表数据（钱包）
    ggwpbyDetailDataJson: {},
    // 操作数据后台收集的数据
    dataList: [],
    // 操作详情数据
    dataJson: {},
    //当前回合所有用户数据-当前回合被奖励的用户
    syncDataJson: {rewardUserName: "", belittleUserId: "", resultDataList: [], resultDataNewList: [], clearState: false},
    // 最后一次提交数据
    ggwpbyLastSyncDataDto: {},
})

const getters = {
    // 获取roomId
    getRoomId(state) {
        return state.ggwpbyRoom.roomId;
    },
    // 获取页面数据
    getPage(state, getters, rootState, rootGetters) {
        let pageIndex = rootState.page.pageIndex;
        return state.ggwpbyConfigList[pageIndex];
    },
}
const mutations = {
    /**
     * 设置用户信息
     * @param state
     * @param data 数据
     */
    setUserInfo(state, data) {
        let {roomId, resultId, userId} = {...data}
        state.ggwpbyResult.roomId = roomId
        state.ggwpbyResult.id = resultId
        state.ggwpbyResult.userId = userId
    },
    /**
     * 页面跳转时，将后台数据同步到前端
     * @param state
     * @param data
     */
    setPageInfo(state, data) {
        let {ggwpbyErtRuleParamJsonDto, ggwpbyLastSyncDataDto, ggwpbyRefereeDto, config, pageIndex} = {...data};
        state.ggwpbyConfigList[pageIndex] = config;
        state.ggwpbyErtRuleParamJsonDto = ggwpbyErtRuleParamJsonDto;
        state.ggwpbyLastSyncDataDto = ggwpbyLastSyncDataDto;
        state.ggwpbyRefereeDto = ggwpbyRefereeDto;
    },
    // dataJson添加数据
    setDataJson(state, data) {
        if (!state.dataJson) {
            state.dataJson = {};
        }
        Object.assign(state.dataJson, data);
    },
    // dataJson添加数据
    nextResult(state, dataJson) {
        // if (!state.syncDataJson.clearState){
            state.ggwpbyRefereeDto.roundSize += 1;
        // }
        state.syncDataJson = {
            "clearState": true,
            "rewardUserName": "",
            "belittleUserId": "",
            "resultDataList": [],
            "resultDataNewList": []
        };
        Object.assign(state.dataJson, dataJson);
    },
    setSyncDataJson(state, dataObject) {
        state.syncDataJson.clearState = false;
        state.syncDataJson.rewardUserName = dataObject.rewardUserName;
        state.syncDataJson.belittleUserId = dataObject.belittleUserId;
        state.syncDataJson.resultDataList = dataObject.hasOwnProperty("resultData") ? dataObject.resultData.resultDataList : [];
        state.syncDataJson.resultDataNewList = dataObject.hasOwnProperty("resultDataNew") ? dataObject.resultDataNew.resultDataList : dataObject.resultData.resultDataList;
    },
    ertEnd({state, rootState}, clear = 0) {
        if (state) state.page = {pageIndex: clear, pageCount: clear}
        if (state) state.progress = {progressIndex: clear}
        if (rootState) rootState.page = {pageIndex: clear, pageCount: clear}
        if (rootState) rootState.progress = {progressIndex: clear}
        Object.assign(state, state);
        Object.assign(rootState, rootState);
        window.clearVuexAlong(true, true);
    },
};
const actions = {
    // 实验初始化方法
    initData({state, rootState}, res) {
        let userErtInfo = res.data
        let refreshState = res.refreshState

        if (refreshState && userErtInfo.pageIndex !== 0) {
            userErtInfo.pageIndex += 1;
        }
        if (rootState.progress.progressIndex === 0) {
            rootState.page = {pageIndex: userErtInfo.pageIndex, pageCount: userErtInfo.pageCount}
            rootState.progress = {progressIndex: userErtInfo.progressIndex}
        }

        // 指导语必须阅读时间 按钮禁用时间
        rootState.sleep.infoReadingTime = userErtInfo.ggwpbyErtRuleParamJsonDto.infoReadingTime;
        rootState.sleep.btnReadingTime = userErtInfo.ggwpbyErtRuleParamJsonDto.btnReadingTime;

        //按钮点击后禁用时间
        rootState.butDisabled.butDisabledTime = 2;
        rootState.butDisabled.clickCountDown = 0;

        delete userErtInfo.progressIndex
        delete userErtInfo.pageIndex
        delete userErtInfo.pageCount

        Object.assign(state, userErtInfo);

        // 操作详情数据
        if (userErtInfo.ggwpbyDetailDataJson) {
            state.dataJson = userErtInfo.ggwpbyDetailDataJson;
        }
        // 当前回合所有用户数据-当前回合被奖励的用户
        let syncDataJson = {
            "rewardUserName": "",
            "belittleUserId": "",
            "resultDataList": [],
            "resultDataNewList": []
        };
        if (userErtInfo.ggwpbyLastSyncDataDto != null) {
            state.dataJson = userErtInfo.ggwpbyLastSyncDataDto.dataJson
            if (userErtInfo.ggwpbyLastSyncDataDto.syncDataJson != null) {
                syncDataJson.resultDataList = eval(userErtInfo.ggwpbyLastSyncDataDto.syncDataJson.resultDataListStr);
                syncDataJson.resultDataNewList = eval(userErtInfo.ggwpbyLastSyncDataDto.syncDataJson.resultDataNewListStr);
                syncDataJson.rewardUserName = userErtInfo.ggwpbyLastSyncDataDto.syncDataJson.rewardUserName;
                syncDataJson.belittleUserId = userErtInfo.ggwpbyLastSyncDataDto.syncDataJson.belittleUserId;
            }
        }
        state.syncDataJson = syncDataJson;
        if (state.config.logState) console.log("初始化后的vuex数据", state)
    },
    /**
     * 保存数据
     * @round 回合数、试次
     * @roundGroup 会合组、轮次
     * @opType 操作类型 1.指导语、2.问题、3.实验数据、4.其他
     * @dataJson 操作数据
     * */
    pushDataList({state, rootState}, object) {
        let result = {
            page: rootState.page.pageIndex + 1,
            userId: state.ggwpbyResult.userId,
            roomId: state.ggwpbyResult.roomId,
            resultId: state.ggwpbyResult.resultId,
            tempPath: state.ggwpbyConfigList[rootState.page.pageIndex].tempPath,
            // round: dataJson.roundSize !== null ? dataJson.roundSize : state.ggwpbyRefereeDto.roundSize,
            round: state.ggwpbyRefereeDto.roundSize,
            ertId: state.ggwpbyResult.ertId,
            // roundGroup: dataJson.roundGroup !== null ? dataJson.roundGroup : state.ggwpbyRefereeDto.roundGroupSize,
            roundGroup: state.ggwpbyRefereeDto.roundGroupSize,
            pegeType: object.opType,
            bitch: state.ggwpbyResult.bitch,
            dataJson: JSON.stringify(object.dataJson),
            // trialCategory: state.ggwpbyResult.trialCategory
        }
        state.dataList.push(result);
        if (state.config.logState) console.log("保存数据", state.dataList)
    },
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
