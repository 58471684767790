<template>
    <div>
        <div class="box-card">
            <el-row class="instruction">
                <el-col :span="24" class="Textc fontwb">总指导语</el-col>
                <el-col :span="24" class="content">
                    <p>感谢你参与本研究。本研究包括两个阶段：问卷填写和多人互动实验。</p>

                    <b>【问卷填写】</b>
                    <p>问卷填写完毕后方可进入多人互动实验。</p>

                    <b>【多人互动实验】</b>
                    <p>在实验过程中，请认真阅读指导语，并严格根据指导语完成相应的选择。</p>
                    <p>
                        包括你在内，共有<strong>16名玩家</strong>参加本次实验。
                        本次实验有两个不同的游戏：<span class="fontRed">公共账户捐资游戏</span>和<span
                        class="fontRed">星级赢币游戏</span>。
                        <u>在每个游戏开始前，系统将随机为所有玩家分配代号：玩家A～玩家P，并<span class="fontRed">为你随机匹配三名不同的玩家</span>，组成临时小组，进行决策游戏。</u>
                        实验全程匿名，其余玩家不会知道你的真实个人信息。
                    </p>
                    <br/>
                    <p>
                        完成实验后，你将获得<span class="fontRed">10元</span>基础报酬。除此之外，你在<span class="fontRed">两个游戏中获得的总代币数会在实验结束后兑换成现金奖励</span>（25代币=1元），连同基础报酬一并发放给你。
                    </p>
                    <br/>
                    <p>
                        因为本实验是<span class="fontRed">多人联网互动游戏</span>，所以实验开始后请勿切换至其他页面，否则系统一旦检测到异常活动，你和其他玩家可能都将无法继续实验。
                        请尊重其他玩家的时间，当其他玩家正在进行选择时，也请您耐心等待。如果您中途退出实验，将无法获得任何报酬。
                    </p>
                    <br/>

<!--                    <p style="text-align: center"><img src="@/assets/ggwpby/C12.png" alt="c12" style="height: 400px;"></p>-->
                    <p style="text-align: center"><img src="@/assets/ggwpby/C21.png" alt="c21" style="height: 400px; margin: 0px auto;"></p>
                    <br/>

                    <p>了解上述规则后请点击“我已知晓”</p>

                </el-col>
                <el-col :span="24" class="Textc">
                    <el-button class="" style="color: #ffffff;margin-top: 30px" @click="matching" type="primary">
                        {{ $t('situation.btn1') }}
                    </el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
    import {getUserKey} from "@/utils/auth";

    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations} = createNamespacedHelpers('ggwpby')
    export default {
        components: {},
        data() {
            return {};
        },
        mounted() {
        },
        methods: {
            ...mapMutations(['setUserInfo']),
            matching() {
                this.$router.push({path: '/common/tasklist/' + getUserKey()})
            }
        }

    }
</script>
<style lang="scss" scoped>
    .box-card {
        width: 70%;
        position: relative;
        left: 50%;
        margin-left: -34%;
        background-color: #eeeeee;

        .el-col {
            font-size: 18px;
            line-height: 40px;
        }
    }
</style>
