<template>
    <div>
        <el-row style="display: flex; justify-content: space-around;">
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">(你)本轮收益：</div>
                <div class="tabclass_db">{{ roundProfitAll }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮从公共账户中获益：</div>
                <div class="tabclass_db">{{ roundProfit }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮个人账户剩余：</div>
                <div class="tabclass_db">{{ noInvestorNumber }}</div>
            </div>
        </el-row>
        <el-row class="center">
            <el-col :span="24">
                <h2 class="fons22">第<span class="colorf00 fons22">{{ roundSizeTemp }}</span>轮</h2>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content Textc"><p>所有玩家选择完毕！</p>
                    <p>你在本轮游戏中的收益为<span class="fontRed">{{roundProfitAll}}</span>个代币</p>
                    <p>（公共账户收益：<span class="fontRed">{{roundProfit}}</span>个代币，个人账户剩余：<span class="fontRed">{{noInvestorNumber}}</span>个代币）</p>
                    <p>本轮游戏中，小组所有玩家投放到公共账户的代币数如下：</p></div>
            </el-col>
        </el-row>
        <el-row style="display: flex; justify-content: space-around; align-content: center">
            <div class="Star">
                <el-row class="header">
<!--                    <el-col :span="6">排名</el-col>-->
                    <el-col :span="12">代号</el-col>
                    <el-col :span="12">投放代币数</el-col>
                </el-row>
                <div class="main">
                    <el-row v-for="(item, index) in rankList" :key="index">
<!--                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.ranking }}</el-col>-->
                        <el-col :span="12" :style="{ color: styleFunc(item)}">{{ item.userGameName }}</el-col>
                        <el-col :span="12" :style="{ color: styleFunc(item)}">{{ item.investorNumber }}个代币</el-col>
                    </el-row>
                </div>
            </div>
        </el-row>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('ggwpby')
    import {styleFunc} from "@/utils/result";
    import Footer from "@/views/ggwpby/components/Footer";

    export default {
        components: {Footer},
        data() {
            return {
                radio: '',
                active: false,
                roundSizeTemp: 1,
                roundProfitAll: 0,
                roundProfit: 0,
                noInvestorNumber: 0,
                rankList: [
                    {ranking: 1, userGameName: '玩家A', investorNumber: 10},
                    {ranking: 2, userGameName: '玩家C', investorNumber: 8},
                    {ranking: 3, userGameName: '你（玩家B）', investorNumber: 8},
                    {ranking: 4, userGameName: '玩家D', investorNumber: 4},
                ],
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyResult', 'ggwpbyRefereeDto', 'dataJson', 'syncDataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            this.load();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            styleFunc,
            ...mapGetters(['getPage']),
            ...mapMutations(['nextResult', 'setDataJson']),
            ...mapActions(['pushDataList']),
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                this.roundSizeTemp = this.ggwpbyRefereeDto.roundSize + 1;

                let page = this.getPage();
                this.rankList = this.syncDataJson.resultDataList;
                this.noInvestorNumber = this.dataJson.noInvestorNumber;
                this.roundProfitAll = this.dataJson.roundProfitAll
                this.roundProfit = this.dataJson.roundProfit
                this.$store.dispatch("sleepTime");
            },
            next() {
                this.$store.dispatch("butDisable");
                this.dataJsonTemp['clickTime'] = this.nowTime;
                this.setDataJson(this.dataJsonTemp);
                this.pushDataList({dataJson: this.dataJson, opType: 3});
                this.$emit("commitData", (res) => {
                    //本回合数据重置
                    this.dataJsonTemp["roundProfit"] = 0;
                    this.nextResult(this.dataJsonTemp);
                    this.$emit("getNextPage", this.dataJson);
                }, true)
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .ml100 {
        margin-left: 100px;
    }

    .header {
        text-align: center;
    }

    .main {
        text-align: center
    }

    .border03 {
        width: 100px;
        color: #03668b;
        border: 1px solid #03668b;
    }


    .fons22 {
        font-size: 22px;
        text-align: center;
        margin-top: 30px;
    }

    .colore99 {
        color: #e99313;
    }
    .colorf00 {
        color: #ff0000;
    }

    .textright {
        text-align: right;
    }

    .grid-content {
        margin-top: 20px;
        line-height: 40px;
        margin-bottom: 20px;
        font-size: 18px;
    }
</style>
