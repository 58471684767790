import modal from "@/utils/modal";

/*
 * 浏览器全屏
 */
function fullScreen() {
    let msg = "为了您的实验体验，已为您开启全屏模式!<br/>如果未开启请手动启动全屏!<br/>(windows快捷键)按F11键<br/>(苹果Safari浏览器快捷键)按control+command+F组合键";
    var el = document.documentElement;
    var rfs = el.requestFullScreen || el.webkitRequestFullScreen;
    if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
    } else if (typeof window.ActiveXObject != "undefined") {
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
            wscript.SendKeys("{F11}");
        }
    } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
    } else if (el.oRequestFullscreen) {
        el.oRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
    } else {
        msg = "浏览器不支持全屏调用!<br/>请更换浏览器或手动开启全屏<br/>(windows快捷键)按F11键<br/>(苹果Safari浏览器快捷键)按control+command+F组合键";
    }
    msg = "<div style='font-size: 14px;line-height: 30px'>" + msg + "</div>"
    modal.msgHtml(msg);
}

/*
 * 浏览器退出全屏
 */
function exitFullScreen() {
    var el = document;
    var cfs = el.cancelFullScreen || el.webkitCancelFullScreen || el.exitFullScreen;
    if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
    } else if (typeof window.ActiveXObject != "undefined") {
        var wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
            wscript.SendKeys("{F11}");
        }
    } else if (el.msExitFullscreen) {
        el.msExitFullscreen();
    } else if (el.oRequestFullscreen) {
        el.oCancelFullScreen();
    } else {
        let msg = "浏览器不支持全屏调用!<br/>请手动退出全屏!";
        msg = "<div style='font-size: 14px;line-height: 30px'>" + msg + "</div>"
        modal.msgHtml(msg);
    }
}

export {fullScreen, exitFullScreen};
