<template>
    <div>
        <el-row style="display: flex; justify-content: space-around;">
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">(你)本轮收益：</div>
                <div class="tabclass_db">{{ roundProfitAll }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮从公共账户中获益：</div>
                <div class="tabclass_db">{{ roundProfit }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮个人账户剩余：</div>
                <div class="tabclass_db">{{ noInvestorNumber }}</div>
            </div>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content Textc"><p>所有玩家选择完毕！</p>
                    <p>每名玩家可从本轮公共投资中获得：<span class="fontRed">{{ roundProfit }}</span>个代币</p>
                    <p>本轮小组成员向公共账户投放代币的情况以及对应的星级信息如下：</p></div>
            </el-col>
        </el-row>
        <el-row style="display: flex; justify-content: space-around;">
            <el-col :span="24" class="Star" style="width: 400px">
                <el-row class="header">
                    <el-col :span="6">星级排名</el-col>
                    <el-col :span="6">代号</el-col>
                    <el-col :span="6">星级数量</el-col>
                    <el-col :span="6">投放代币数</el-col>
                </el-row>
                <el-col :span="24" class="main">
                    <el-row v-for="(item, index) in rankList" :key="index" style="text-align: center">
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.ranking }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.userGameName }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.starsCount }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.investorNumber }}个代币</el-col>
                    </el-row>
                </el-col>
            </el-col>
        </el-row>
        <br/>
        <br/>
        <el-row>
            <el-col v-if="belittleState">
                <el-row>
                    <el-col :span="24" class="Textc  fons22">
                        经过系统随机选择，本轮游戏中你有资格降低指定玩家的星级。
                    </el-col>
                    <el-col :span="24" class="Textc  fons22">
                        现在你可以通过向系统支付代币，选择一名玩家，降低其在本轮的星级。
                    </el-col>
                    <el-col :span="24" class="Textc  fons22">
                        （<span class="TextColorE99313">1代币</span>降低<span class="TextColorE99313">1颗星</span>，最多可降低至<span
                        class="TextColorE99313">0颗星</span>）
                    </el-col>
                    <el-col :span="24" class="Textc  fons22 fontRed">
                        你是否想要降低其他玩家的星级？
                    </el-col>
                    <el-col :span="24" style="font-size: 18px; margin-top: 20px; text-align: center">
                        <el-button class="fontwb" :type="beNoBelittle ? 'primary' : 'text'"
                                   @click="beNoBelittle = true">{{
                            $t('situation.btn3')
                            }}
                        </el-button>
                        <el-button class="fontwb  ml100"
                                   :type="!beNoBelittle && beNoBelittle !=null ? 'primary' : 'text'"
                                   @click="beNoBelittle = false, belittleUserRadio = false, belittle = 0">
                            {{ $t('situation.btn4') }}
                        </el-button>
                    </el-col>
                </el-row>
                <el-row v-if="beNoBelittle" class="Textc">
                    <el-col :span="24" class=" fons22">
                        <el-radio-group v-model="belittleUserRadio" @change="belittleStars">
                            <el-radio :label="item.userGameName" v-for="(item, index) in belittleUserList" :key="index">
                                {{ item.userGameName }}
                            </el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row v-if="belittleUserRadio" class="Textc">
                    <el-col :span="24" class=" fons22">
                        输入你想要为{{ belittleUserRadio }}降低的星级数量（大于等于零的整数）：
                    </el-col>
                    <el-col :span="24" class=" fons22">
<!--                        <el-input-number class="" v-model="belittle" :max="belittleCountMaxTemp" :min="0"-->
<!--                                         :precision="0" :step="1" @change="belittleStars"></el-input-number>-->
                        <el-input-number
                                  v-model.number="belittle"
                                  :min="0"
                                  :max="belittleCountMaxTemp"
                                  @change="belittleStars"
                                  @input.native="onInput0_max"
                                  onKeypress="return (/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
                               ></el-input-number>
                    </el-col>
                    <el-col :span="24" class=" fons22">
                        （玩家{{ belittleUserRadio }}降低后星级为{{ belittleXJTemp }}颗星，你需要支付{{ belittleDBTemp }}个代币）
                    </el-col>
                </el-row>
            </el-col>

            <el-col v-else>
                <el-row>
                    <el-col :span="24" class="Textc  fons22">
                        经过系统随机选择，本轮游戏中你没有资格降低指定玩家的星级。
                    </el-col>
                    <el-col :span="24" class="Textc  fons22">
                        请耐心等待系统选中的玩家进行选择。
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapGetters, mapMutations, mapActions, mapState} = createNamespacedHelpers('ggwpby');
    import {styleFunc} from "@/utils/result";
    import Footer from "@/views/ggwpby/components/Footer";

    export default {
        components: {Footer},
        data() {
            return {
                belittleUserRadio: null,
                initialCoinCountTemp: 10,
                belittleUserList: [],
                roundProfitAll: 0,
                roundProfit: 0,
                noInvestorNumber: 0,
                starsRatioTemp: 0,
                beNoBelittle: null,
                belittle: 0,
                belittleState: null,
                belittleLowerLimitTemp: 10,
                belittleCountMaxTemp: 10,
                starsTemp: 0,
                belittleXJTemp: 0,
                belittleDBTemp: 0,
                rankList: [
                    {ranking: 1, userGameName: '玩家A', starsCount: 8, investorNumber: 10},
                    {ranking: 2, userGameName: '玩家C', starsCount: 4, investorNumber: 8},
                    {ranking: 3, userGameName: '你（玩家B）', starsCount: 5, investorNumber: 8},
                    {ranking: 4, userGameName: '玩家D', starsCount: 3, investorNumber: 4},
                ],
                rankListTemp: [],
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyResult', 'ggwpbyRefereeDto', 'ggwpbyErtRuleParamJsonDto', 'dataJson', 'syncDataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            this.load();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            styleFunc,
            ...mapGetters(['getPage']),
            ...mapMutations(['nextResult', 'setDataJson', 'setSyncDataJson']),
            ...mapActions(['pushDataList']),

            onInput0_max(e) {
                let max = this.belittleCountMaxTemp;
                //关闭提示
                this.$modal.msgClose();
                let value = e.target.value;
                if (!value.match(/^\d{1,3}(\.\d*)?$/)) {
                    value = 0
                }
                value = Number(value)
                if (value < 0 || value > max) {
                    this.$modal.msgWarning("该玩家最高可贬低 " + max + " 颗星！");
                }
                e.target.value = value < 0 ? 0 : value;
                e.target.value = value > max ? max : value;
                this.belittle = e.target.value;
                this.belittleStars();
            },
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                this.rankList = this.syncDataJson.resultDataList;
                this.noInvestorNumber = this.dataJson.noInvestorNumber;
                this.roundProfitAll = this.dataJson.roundProfitAll;
                this.roundProfit = this.dataJson.roundProfit;
                this.belittleLowerLimitTemp = this.ggwpbyErtRuleParamJsonDto.belittleLowerLimit;
                this.belittleState = this.ggwpbyResult.userId == this.dataJson.belittleUserId;
                if (this.config.logState) console.log("贬低前", this.syncDataJson)
                this.rankList.forEach(rank => {
                    if (rank.userGameName.indexOf(this.ggwpbyResult.userGameName) !== -1) {
                        this.starsTemp = rank.starsCount;
                    } else {
                        this.belittleUserList.push(rank);
                    }
                });
                this.$store.dispatch("sleepTime");
            },

            /**
             * 取被选中的被试投资数
             * @returns {number}
             */
            belittleStars() {

                let starsRatio = this.ggwpbyErtRuleParamJsonDto.starsRatio;
                if (starsRatio) {
                    let starsRatioSplit = starsRatio.split(",");
                    if (starsRatioSplit.length === 2) {
                        this.rankList.forEach(rank => {

                            if (rank.userGameName.indexOf(this.belittleUserRadio) !== -1) {

                                this.belittleXJTemp = Number(rank.starsCount) - Number(this.belittle);
                                this.belittleDBTemp = this.belittle / starsRatioSplit[0];

                                //可贬低数
                                let belittleCountMax = Number(rank.starsCount - this.belittleLowerLimitTemp);
                                //不可超过可使用代币
                                this.belittleCountMaxTemp = belittleCountMax > this.dataJson.roundProfitAll ? this.dataJson.roundProfitAll : belittleCountMax;
                            }
                        });
                    }
                }
            },
            next() {
                this.$store.dispatch("butDisable");
                this.$modal.msgClose();

                this.dataJsonTemp['clickTime'] = this.nowTime;
                this.dataJsonTemp["beNoBelittle"] = this.beNoBelittle;
                this.dataJsonTemp["investorBelittleUserId"] = null;
                this.dataJsonTemp["investorBelittle"] = this.belittle;
                this.dataJsonTemp["investorBelittleNumber"] = null;

                if (this.belittleState) {

                    if (this.beNoBelittle == null) {
                        this.$modal.msgWarning("请输入是否需要贬低他人！");
                        return;
                    } else if (this.beNoBelittle) {
                        if (this.belittleUserRadio == null) {
                            this.$modal.msgWarning("请选择需要贬低的被试！");
                            return;
                        }

                        if (this.belittle === 0) {
                            this.$modal.msgWarning("请输入需要贬低他人的星级数量！");
                            return;
                        }
                    }

                    this.belittleUserList.map(user => {
                        if (user.userGameName === this.belittleUserRadio) {
                            this.dataJsonTemp["investorBelittleUserId"] = user.userId;
                            return
                        }
                    })

                    this.rankListTemp = this.rankList;
                    this.rankListTemp.forEach(rank => {
                        let number = rank.userGameName.indexOf("玩家") + 2;
                        rank.userGameName = rank.userGameName.substring(number, number + 1)
                    })
                    this.rankListTemp.forEach(rank => {
                        if (this.dataJsonTemp.investorBelittleUserId == rank.userId) {
                            let investorBelittleNumber = Number((this.belittle * this.ggwpbyErtRuleParamJsonDto.transactionRatio).toFixed(2))
                            let belittleUserInvestorNumber = Number((rank.starsCount - investorBelittleNumber).toFixed(2))
                            rank.starsCount = belittleUserInvestorNumber < this.ggwpbyErtRuleParamJsonDto.belittleLowerLimit ? this.ggwpbyErtRuleParamJsonDto.belittleLowerLimit : belittleUserInvestorNumber
                            this.dataJsonTemp["investorBelittleNumber"] = rank.starsCount
                        }
                    })
                    if (this.config.logState) console.log("贬低最终", this.syncDataJson, this.dataJsonTemp)
                    this.dataJsonTemp["roundProfit"] = Number(this.dataJson.roundProfit);
                    this.dataJsonTemp["roundProfitAll"] = (Number(this.dataJson.roundProfitAll) - Number(this.belittleDBTemp)).toFixed(2);
                    this.dataJsonTemp["roundGroupProfit"] = (Number(this.dataJson.roundGroupProfit) - Number(this.belittleDBTemp)).toFixed(2);
                    this.dataJsonTemp["wallet"] = (Number(this.dataJson.wallet) - Number(this.belittleDBTemp)).toFixed(2);
                    // 将数据存储到全局变量dataJson中
                    this.setDataJson(this.dataJsonTemp);

                    this.syncDataJson.resultDataList = this.rankListTemp
                    this.putErtRate4();
                }

                this.$emit("getNextPage", this.dataJson);
            },

            putErtRate4() {
                // 同步自己的选择 roomId房间ID， userId:用户ID, tailType:重复次数，roundNumber:回合数, dataStr: 你的选择, createTime: 选择时间。
                let tempJson = {
                    roomId: this.ggwpbyResult.roomId,
                    groupSize: this.ggwpbyRefereeDto.groupSize,
                    groupUserCount: this.ggwpbyErtRuleParamJsonDto.groupUserCount,
                    roundGroupSize: this.ggwpbyRefereeDto.roundGroupSize,
                    roundSize: this.ggwpbyRefereeDto.roundSize,
                    starsRatio: this.ggwpbyErtRuleParamJsonDto.starsRatio,
                    userId: this.ggwpbyResult.userId,
                    userName: this.ggwpbyResult.userGameName,
                    dataJson: JSON.stringify(this.syncDataJson.resultDataList),
                    // createTime: getNowTime()
                };

                let socket = this.$store.getters.getSocket;
                socket.emit("ggwpby_putErtRate4", tempJson, (res) => {
                    if (res.code === 0) {

                        // this.syncDataJson.resultDataList = this.rankList
                        // this.$emit("getNextPage", this.dataJson);
                    } else {
                        // 定时调用获取对手数据
                        setTimeout(() => {
                            this.putErtRate4();
                        }, 1000 * 10);
                    }
                })
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                if (this.belittleState) {
                    this.beNoBelittle = Math.round(Math.random() * Number(1)) > 0;
                    if (this.beNoBelittle) {
                        this.belittleUserRadio = this.belittleUserList[Math.floor(Math.random() * Number(this.belittleUserList.length))].userGameName;
                        this.belittleStars();
                        this.belittle = Math.floor(Math.random() * Number(this.belittleCountMaxTemp) + 1);
                        this.belittleStars();
                    }
                }
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .ml100 {
        margin-left: 100px;
    }

    .header {
        text-align: center;
    }

    .fons22 {
        font-size: 22px;
        margin-top: 20px;
        letter-spacing: 5px;
    }


    .grid-content {
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 40px;
        font-size: 18px;
    }

    ::v-deep .el-radio__inner {
        width: 22px;
        height: 22px;
    }

    ::v-deep .el-radio__label {
        font-size: 22px;
    }

    ::v-deep .el-radio {
        align-items: center;
        justify-content: center;
        display: inline-flex;
    }
</style>
