<template>
  <div>
    <el-select v-model="active_lang" placeholder="" @change="changeLang">
      <el-option
          v-for="item in language"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      active_lang: '0',
      language: [
        {
          value: '0',
          label: '中文简体'
        },
        {
          value: '1',
          label: 'English'
        },
      ],
    };
  },
  mounted() {

  },
  methods: {
    changeLang() {
      if (this.active_lang === '0') {
        this.$i18n.locale = 'zh';
        Cookies.set('language', 'zh')
      } else if (this.active_lang === '1') {
        this.$i18n.locale = 'en';
        Cookies.set('language', 'en')
      }
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
