module.exports = {
    language: {
        name: "中文简体"
    },
    situation: {
        btn: '我已经确认协议',
        btn1: '我已知晓',
        btn2: '取消组队',
        btn3: '是',
        btn4: '否',
        btn5: '继续',
        btn6: '返回指导语',
        endBtn: '退出',
    },
    login: {
        userTip: "请登录",
        registerFree: "免费注册",
        forget: "忘记密码"
    },
    matching: {
        text: '欢迎进入第一阶段的决策游戏.在本阶段开始前，将会把你们(16个参与者)分为4组，每组4人。同一组的玩家将共同完成2轮的决策游戏。每一轮游戏开始前都会为每个人发放10个代币，你们需要同时决定，从10个代币中拿取多少投放至"公共账户"，剩余的代币累积至个人账户为你们个人所有。全体成员投放完毕后，"公共账户"中的总金额会翻2倍平分给每位成员。因此，将代币投放至公共账户对全体成员有益，保留到个人账户的钱只有益于你自己。　 　 例如，4名玩家都投放了5个代币直公共账户，那么他们分别将从公共账户获益((5+5+5+5)*2/4)个代币。你每一轮的收益都由个人账户和从公共账户中获益的代币数共同组成。每轮收益= 10-你的投放金额+所有人投放金额*2/4从每一轮结束后，你可以看到其他玩家的投放数量以及你本轮的收益。',
        startQuestion: '开始填写',
        awaitButton: '等待其他成员',
        startButton: '开始匹配',
        continueButton: '继续实验',
        finishButton: '已完成',
        causeButton: '中途退出',
        dialog: '正在匹配中，请稍等！',
        dialog2: '点击取消按钮可停止组队',
        notQuestion: '未开始问卷',
        notStart: "未开始实验",
        notFinish: "未完成实验",
        finish: '已完成',


    }
}

