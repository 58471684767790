<template>
    <div>
        <br/><br/><br/>
        <el-row class="center">
            <el-col :offset="8" :span="8" v-html="html">

            </el-col>
            <el-col :offset="6" :span="12">
                <p>你的玩家代号为：<b class="colorf00 ">玩家{{ userName }}</b></p>
                <br/>
                <p>你将以玩家{{ userName }}的身份完成{{ertName}}</p>
            </el-col>
        </el-row>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('ggwpby')
    import Footer from "@/views/ggwpby/components/Footer"

    export default {
        components: {Footer},
        data() {
            return {
                html: null,
                userName: 'A',
                ertName: "公共物品博弈",
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyResult', 'dataJson', 'ggwpbyRefereeDto']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            this.load();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            ...mapGetters(['getPage']),
            ...mapActions(['pushDataList']),
            ...mapMutations(['setDataJson']),
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                let page = this.getPage();
                this.html = page.dataJson;
                this.userName = this.ggwpbyResult.userGameName;
                let ruleParamJsonDto = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto;
                this.ertName = ruleParamJsonDto.ertName;
                this.$store.dispatch("sleepTime");
            },
            next() {
                this.$store.dispatch("butDisable");
                this.dataJsonTemp['clickTime'] = this.nowTime;
                this.setDataJson(this.dataJsonTemp);
                this.pushDataList({dataJson: this.dataJson, opType: 4});
                this.$emit("commitData", (res) => {
                    this.$emit("getNextPage", this.dataJson);
                })
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }

    }
</script>
<style lang="scss" scoped>
    .center {
        text-align: center;
    }

    p {
        font-size: 18px;
    }

    .fons18 {
        font-size: 18px;
    }

    .fons22 {
        font-size: 22px;
    }
    .colorf00 {
        color: #ff0000;
    }
</style>
