import Cookies from 'js-cookie'


const TokenKey = 'websiteUser';
const userKey = 'userKey';
const expKey = "expKey";

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

/**
 * userkey
 * @returns {*}
 */
export function getUserKey() {
    return Cookies.get(userKey)
}

export function setUserKey(data) {
    return Cookies.set(userKey, data)
}

export function removeUserKey() {
    return Cookies.remove(userKey)
}

/**
 * 实验标识
 * @returns {*}
 */
export function getExpKey() {
    return Cookies.get(expKey)
}

export function setExpKey(data) {
    return Cookies.set(expKey, data)
}

export function removeExpKey() {
    return Cookies.remove(expKey)
}
