<template>
    <div>
        <br/><br/><br/>
        <el-row class="text-center fons18">
            <el-col :offset="4" id="detail" :span="16">
                <p>匹配完成，即将进入<span style='color: red'>{{ertName}}</span>决策环节</p><br/>
                <p>作为<span style='color: red; border-bottom: 1px solid red'>玩家{{ userName }}</span>，
                    你将与<span style='color: red; border-bottom: 1px solid red' v-for="(item, index) in oppoList"
                             :key="index">
                        玩家{{ item }}<span v-if="index !== oppoList.length - 1">、</span>
                        </span> 组成临时小组
                </p><br/>
                <p>
                    共同完成{{ roundSize }}轮左右的{{ertName}}（具体轮数由系统随机决定）
                </p>
            </el-col>
        </el-row>
        <br/><br/>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations, mapGetters, mapActions, mapState} = createNamespacedHelpers('ggwpby')
    import Footer from "@/views/ggwpby/components/Footer"

    export default {
        components: {Footer},
        data() {
            return {
                html: "",
                roundGroupSize: 1,
                roundSize: 1,
                ertName: "公共物品博弈",
                dataJsonTemp: {},
                userName: 'A',
                oppoList: ['B', "C", "D"]
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyRefereeDto', 'ggwpbyResult', 'dataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            // 页面加载方法
            this.load();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            ...mapGetters(['getPage']),
            ...mapActions(['pushDataList']),
            ...mapMutations(['setDataJson']),
            // 页面加载方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                let page = this.getPage();
                this.html = page.dataJson;
                let userGameNames = this.ggwpbyRefereeDto.ggwpbySchedule.userGameNames;
                this.userName = this.ggwpbyResult.userGameName;
                this.roundSize = this.ggwpbyRefereeDto.ggwpbyRandomDto.roundSize
                let userNameSplit = userGameNames.split(",");
                userNameSplit.splice(userNameSplit.indexOf(this.userName), 1)
                this.oppoList = userNameSplit
                let ruleParamJsonDto = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto;
                this.ertName = ruleParamJsonDto.ertName;
                this.$store.dispatch("sleepTime");
            },
            next() {
                this.$store.dispatch("butDisable");
                // 提交当前页面数据：并跳转下一页面
                this.dataJsonTemp['clickTime'] = this.nowTime;
                // 将数据存储到全局变量dataJson中
                this.setDataJson(this.dataJsonTemp);
                this.pushDataList({dataJson: this.dataJson, opType: 4});
                this.$emit("commitData", (res) => {
                    this.$emit("getNextPage", this.dataJson);
                })
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {

                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }

    }
</script>
<style lang="scss" scoped>

    .text-center {
        text-align: center;
    }

    .fons18 {
        font-size: 18px;
    }
</style>
