import store from "@/store"

document.onmousemove = handleMouseMove;
let registeredMoves = 0;
let moves = 10;

function handleMouseMove(event) {
    if(moves === 0) {
        moves = 10;
        event = event || window.event; //Older Browser support
        if (event.pageX == null && event.clientX != null) {
            let eventDoc, doc, body, pageX, pageY;
            eventDoc = (event.target && event.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;
            event.pageX = event.clientX +
                (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
                (doc && doc.clientLeft || body && body.clientLeft || 0);
            event.pageY = event.clientY +
                (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
                (doc && doc.clientTop  || body && body.clientTop  || 0 );
        }
        store.state.mouseList.push({x: event.pageX, y: event.pageY})
        // console.log({x: event.pageX, y: event.pageY})
        registeredMoves++;
    } else {
        moves--;
    }
}
