const state = () => ({
    a: "3",
    b: "4"
})

const getters = {
    a: (state) => {
        return state.a
    },
    b: (state) => {
        return state.b
    }
}
const mutations = {}
const actions = {}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
