<template>
    <div>
        <br/><br/><br/>
        <el-row class="center">
            <!--<el-col :offset="6" :span="12">
                <p>全部游戏结束！</p><br/>
                <p>在<span class="TextColorE99313 fons18">{{ count }}</span>个游戏中，您总共获得<span
                    class="TextColorE99313 fons18">{{ money }}</span>个代币（50代币 = 1元）</p><br/>
                <p>您最终的实验报酬为<span class="TextColorE99313 fons18">{{ amount }}元</span> 。</p><br/>
                <p>（总报酬 = 固定实验报酬 + 实验获得的代币数）</p><br/>
                <p>实验报酬将通过您留下的支付方式发放给您！</p><br/>
                <p>感谢您参与本实验！</p><br/>
            </el-col>-->

            <el-col :offset="6" :span="12">
                <p>{{ertName}}游戏结束！</p><br/>
                <p>请点击按钮继续切换至实验列表</p><br/>
            </el-col>
        </el-row>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations, mapGetters, mapState} = createNamespacedHelpers('ggwpby')
    import {getUserKey} from "@/utils/auth";
    import Footer from "@/views/ggwpby/components/Footer"

    export default {
        components: {Footer},
        data() {
            return {
                ertName: "公共物品博弈",
                count: 1,
                money: 400,
                amount: 4.00,
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'dataJson', 'ggwpbyRefereeDto']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            this.load();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                this.money = this.dataJson.wallet
                this.amount = Math.round((this.dataJson.wallet * parseFloat(1 / this.config.tokenMoney)) * 10000 / 100) / 100;
                let ruleParamJsonDto = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto;
                this.ertName = ruleParamJsonDto.ertName;
                this.$store.dispatch("sleepTime");
            },
            next() {
                this.dataJsonTemp['clickTime'] = this.nowTime;
                this.$store.dispatch("butDisable");
                this.$emit("endErt", this.money);
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }

    }
</script>
<style lang="scss" scoped>
    .center {
        text-align: center;
    }

    p {
        font-size: 18px;
    }

    .fons18 {
        font-size: 18px;
    }

    .fons22 {
        font-size: 22px;
    }
</style>
