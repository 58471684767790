<template>
    <div>
        <el-row style="display: flex; justify-content: space-around;">
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">(你)本轮收益：：</div>
                <div class="tabclass_db">{{ roundProfitAll }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮从公共账户中获益：</div>
                <div class="tabclass_db">{{ roundProfit }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮个人账户剩余：</div>
                <div class="tabclass_db">{{ noInvestorNumber }}</div>
            </div>
            <div class="tabclass" style="width: 200px">
                <div class="tabclass_text bgc03628e">奖励：</div>
                <div class="tabclass_db">{{ reward }}</div>
            </div>
            <div class="tabclass" style="width: 200px" v-if="ertKey !== 'C2' && belittleState">
                <div class="tabclass_text bgc03628e">{{ hintDebase }}：</div>
                <div class="tabclass_db">{{ investorPromote }}{{ investorBelittle }}</div>
            </div>
        </el-row>
        <el-row class="center">
            <el-col :span="24">
                <h2 class="fons22">第<span class="colorf00">{{ roundSizeTemp }}</span>轮</h2>
            </el-col>
        </el-row>
        <el-row style="display: flex; justify-content: space-around; align-content: center;margin-top: 50px">
            <el-col :span="24" class="Star">
                <el-row class="header">
                    <el-col :span="10">{{ rewardTitle }}</el-col>
                    <el-col :span="14">代号</el-col>
                </el-row>
                <el-col :span="24" class="main">
                    <el-row v-for="(item, index) in rankList" :key="index" style="text-align: center">
                        <el-col :span="10" :style="{ color: styleFuncWin(item)}">{{ item.ranking }}</el-col>
                        <el-col :span="14" :style="{ color: styleFuncWin(item)}">
                            {{ item.userGameName }}
                            <el-image v-if="item.win"
                                      style="width: 30px; height: 30px;vertical-align: middle"
                                      :src="require('@/assets/money.png')"
                                      :fit="'contain'"></el-image>
                        </el-col>
                    </el-row>
                </el-col>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content Textc" v-html="content"></div>
            </el-col>
        </el-row>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'
    import {styleFuncWin} from "@/utils/result";
    import Footer from "@/views/ggwpby/components/Footer"

    const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('ggwpby')

    export default {
        components: {Footer},
        data() {
            return {
                roundSizeTemp: 1,
                currentUserName: 'A',
                rewardUserName: 'B',
                rewardTitle: "最终星级排名",
                ertKey: "C2",
                hintDebase: "降低星级",
                reward: 0,
                content: '<p>本轮游戏中玩家B被抽中为赢家，玩家B获得<span class="TextColorE99313">25</span>个代币的奖励，存入你的个人账户</p> <p>点击继续进入下一轮</p>',
                roundProfitAll: 0,
                roundProfit: 0,
                noInvestorNumber: 0,
                investorPromote: null,
                investorBelittle: null,
                belittleState: true,
                rankList: [
                    {ranking: 1, userGameName: '玩家A'},
                    {ranking: 2, userGameName: '玩家C', win: true},
                    {ranking: 3, userGameName: '你（玩家B）'},
                    {ranking: 4, userGameName: '玩家D'},
                ],
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyResult', 'ggwpbyRefereeDto', 'dataJson', 'syncDataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            this.load();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            styleFuncWin,
            ...mapGetters(['getPage']),
            ...mapMutations(['nextResult', 'setDataJson']),
            ...mapActions(['pushDataList']),
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                this.roundSizeTemp = this.ggwpbyRefereeDto.roundSize + 1;

                this.rankList = this.syncDataJson.resultDataNewList;

                this.noInvestorNumber = this.dataJson.noInvestorNumber;
                this.roundProfitAll = this.dataJson.roundProfitAll;
                this.roundProfit = this.dataJson.roundProfit;
                let rewardTemp = Number(this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto.reward);
                let rewardUserName = this.dataJson.rewardUserName;

                if (rewardUserName == this.ggwpbyResult.userGameName) {
                    this.reward = rewardTemp;
                    this.roundProfitAll = Number(this.roundProfitAll) + Number(this.reward);
                    this.plusRewards();

                    this.content = '<p>恭喜你（玩家' + rewardUserName + '）被抽中为本轮的赢家，系统奖励你' + rewardTemp + '个代币的奖励，存入你的个人账户</p> '
                } else {
                    this.content = '<p>本轮游戏中<span class="fontRed">玩家' + rewardUserName + '</span>被抽中为赢家，玩家' + rewardUserName + '获得' + rewardTemp + '个代币的奖励。</p>'
                }

                this.content += '<p>你在本轮游戏中的收益为<span class="fontRed">' + this.roundProfitAll + '</span>个代币</p>' +
                '<p>（公共账户收益：<span class="fontRed">' + this.roundProfit + '</span>个代币，个人账户剩余：<span class="fontRed">' + this.noInvestorNumber + '</span>个代币，奖励：<span class="fontRed">' + this.reward + '</span>个代币）</p>' +
                '<p>点击继续进入下一轮</p>';

                this.ertKey = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto.ertKey;
                if (this.ertKey === 'C2') {
                    this.rewardTitle = "星级排名"
                } else {
                    this.rewardTitle = "最终星级排名"
                    if (this.ertKey === 'C3') {
                        this.hintDebase = "提升星级"

                        this.investorPromote = this.dataJson.investorPromote;
                    } else {
                        this.hintDebase = "降低星级"
                        this.investorBelittle = this.dataJson.investorBelittle;
                        this.belittleState = this.ggwpbyResult.userId == this.dataJson.belittleUserId
                    }
                }
                this.$store.dispatch("sleepTime");
            },
            plusRewards() {
                //追加奖励代币
                //本回合收益（公共账户）、本回合收益（全部）、本轮次收益、钱包（总）
                this.dataJsonTemp["roundProfitAll"] = (Number(this.dataJson.roundProfitAll) + Number(this.reward)).toFixed(2);
                this.dataJsonTemp["roundGroupProfit"] = (Number(this.dataJson.roundGroupProfit) + Number(this.reward)).toFixed(2);
                this.dataJsonTemp["wallet"] = (Number(this.dataJson.wallet) + Number(this.reward)).toFixed(2);
            },
            next() {
                this.$store.dispatch("butDisable");
                this.dataJsonTemp['clickTime'] = this.nowTime;
                this.setDataJson(this.dataJsonTemp);
                this.pushDataList({dataJson: this.dataJson, opType: 3});
                this.$emit("commitData", (res) => {
                    //本回合数据重置
                    this.dataJsonTemp["roundProfit"] = 0;
                    this.dataJsonTemp["roundProfitAll"] = 0;
                    this.nextResult(this.dataJsonTemp);
                    this.$emit("getNextPage", this.dataJson);
                }, true)
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }

    }
</script>
<style lang="scss" scoped>
    .header {
        text-align: center;
    }

    .footer {
        position: fixed;
        bottom: 20px;
        margin: 0 auto;
        width: 97.25%;
    }

    .grid-content {
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 40px;
        font-size: 18px;
    }
    .fons22 {
        font-size: 22px;
        text-align: center;
        margin-top: 30px;
    }
    .colorf00 {
        color: #ff0000;
    }
</style>
