import store from '@/store/index'

/**
 * 获取网速
 */
export function getSpeed() {
    let image = new Image(); //定义Image对象
    // let imageSrc = 'http://www.baidu.com/img/baidu_jgylogo3.gif'
    let imageSrc = 'http://speed.webpsychstudies.com/speed.gif'
    image.src = imageSrc + '?n=' + Math.random(); //随机访问该图片资源
    let startTime = new Date().getTime(); //开始下载时的时间戳
    image.onload = () => { //图片加载完时会执行的回调函数
        let endTime = new Date().getTime(); //完成下载的时的时间
        let diffSeconds = (endTime - startTime); //差时间转为秒
        let net;
        if (diffSeconds > 200) {
            net = {speed: "网速过慢", color: "red"}
        } else if (diffSeconds > 100) {
            net = {speed: "网速中等", color: "yellow"}
        } else {
            net = {speed: "网速极佳", color: "#10fe01"}
        }
        store.commit('setSpeed', net);
    };
}
// 获取网速
getSpeed()
window.setInterval(getSpeed, 10 * 1000)
