// 判断 中奖的和你自己的颜色
export function styleFunc(item) {
     if (item.userGameName.startsWith("你")) {
        return "red";
    }
    return "";
}// 判断 中奖的和你自己的颜色
export function styleFuncWin(item) {
    if (item.userGameName.startsWith("你") && item.win) {
        return "red";
    } else if (item.userGameName.startsWith("你")) {
        return "red";
    } else if (item.win) {
        return "green";
    }
    return "";
}


/**
 * 四舍五入，保留指定小数位数，当原始数据不满足长度时，不要补 0
 * @param number [Number | String] 原始数据，支持字符串和数值型
 * @param i [Number] 保留的小数个数 >= 0
 * @returns [Number] 转换后的数据
 */
export function transformDecimal(number, i) {
    let decimalNum = null;
    // 先转换为数值型
    let num = Number(number);
    // 判断是否为数值型
    if(!isNaN(num)) {
        // 切分整数与小数
        let arr = num.toString().split(".");
        // 是小数且小数位大于保留个数
        if(arr.length > 1 && arr[1].length > i) {
            // 小数部分字符串
            let decimal = arr[1].slice(i, i+1);
            // toFixed 有 bug，四舍六入五随机
            // 当四舍五入的数为 5，给其 + 1
            if(decimal === '5') {
                // 这里可能会存在 0.1 ** 5 = 0.000010000000000000003 但不影响四舍五入
                num += Math.pow(0.1, i+1);
            }
            decimalNum = num.toFixed(i);
        }
        else {
            decimalNum = num;
        }
        decimalNum = Number(decimalNum);
    }
    return decimalNum;
}
