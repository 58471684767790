import  request from "@/utils/request"


// 查询实验列表
export function tasklist() {
    return request({
        url: '/ggwpby/web/tasklist',
        method: 'get',
    })
}


// 开始匹配
export function matchRoom(ertId) {
    return request({
        url: '/ggwpby/study/matchRoom/' + ertId,
        method: 'post',
    })
}


// 查询组队是否成功
export function queryTeam(query) {
    return request({
        url: '/ggwpby/study/queryTeam',
        method: 'get',
        params: query
    })
}

// 停止组队
export function stopMatchTeam(ertId) {
    return request({
        url: '/ggwpby/study/stopMatchTeam/' + ertId,
        method: 'post',
    })
}

// 查询是否正在组队
export function queryWait(query) {
    return request({
        url: '/ggwpby/study/queryWait',
        method: 'get',
        params: query
    })
}
