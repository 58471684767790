import Ggwpby from "@/components/ggwpby";
import {getExpKey} from "@/utils/auth";
import store from "@/store"

const userKey = "/:userKey"

const router = [
    {
        path: '/ggwpby',
        component: Ggwpby,
        redirect: 'index',
        meta: {title: '首页', icon: 'dashboard', affix: true},
        //路由独享守卫
        beforeEnter: (to, from, next) => { //路由独享守卫 前置
            if (store.state.ggwpby.expKey === getExpKey()) {
                next()
            } else {
                next({path: "/"})
            }
        },
        children: [
            {
                path: 'index' + userKey,
                component: () => import("@/views/ggwpby/template/index.vue"),
                name: 'index',
                meta: {title: '游戏规则'}
            },
            {
                path: 'info' + userKey,
                name: "info",
                component: () => import("@/views/ggwpby/template/info.vue"),
                meta: {title: '指导语', keepAlive: false}
            },
            {
                path: 'matched' + userKey,
                name: "matched",
                component: () => import("@/views/ggwpby/template/matched.vue"),
                meta: {title: '匹配完成'}
            },
            {
                path: 'rule' + userKey,
                name: "rule",
                component: () => import("@/views/ggwpby/template/rule.vue"),
                meta: {title: '角色分配'}
            },
            {
                path: 'problem' + userKey,
                name: "problem",
                component: () => import("@/views/ggwpby/template/c1_problem.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'problem1f' + userKey,
                name: "problem1f",
                component: () => import("@/views/ggwpby/template/c1_problem_first.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'problem2' + userKey,
                name: "problem2",
                component: () => import("@/views/ggwpby/template/c2_problem.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'problem2f' + userKey,
                name: "problem2f",
                component: () => import("@/views/ggwpby/template/c2_problem_first.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'problem3' + userKey,
                name: "problem3",
                component: () => import("@/views/ggwpby/template/c3_problem.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'problem3f' + userKey,
                name: "problem3f",
                component: () => import("@/views/ggwpby/template/c3_problem_fitst.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'problem4' + userKey,
                name: "problem4",
                component: () => import("@/views/ggwpby/template/c4_problem.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'problem4f' + userKey,
                name: "problem4f",
                component: () => import("@/views/ggwpby/template/c4_problem_first.vue"),
                meta: {title: '问题页面'}
            },
            {
                path: 'ert_investor' + userKey,
                name: "ert_investor",
                component: () => import("@/views/ggwpby/template/ert_investor.vue"),
                meta: {title: '投放代币'}
            },
            {
                path: 'ert_guess' + userKey,
                name: "ert_guess",
                component: () => import("@/views/ggwpby/template/ert_guess.vue"),
                meta: {title: '其他玩家投放'}
            },
            {
                path: 'result_await' + userKey,
                name: "result_await",
                component: () => import("@/views/ggwpby/template/result_await.vue"),
                meta: {title: '等待其他玩家'}
            },
            {
                path: 'result' + userKey,
                name: "result",
                component: () => import("@/views/ggwpby/template/c1_result.vue"),
                meta: {title: '结果页面'}
            },
            {
                path: 'result2' + userKey,
                name: "result2",
                component: () => import("@/views/ggwpby/template/c2_result.vue"),
                meta: {title: '结果页面'}
            },
            {
                path: 'result3' + userKey,
                name: "result3",
                component: () => import("@/views/ggwpby/template/c3_result.vue"),
                meta: {title: '结果页面'}
            },
            {
                path: 'result4' + userKey,
                name: "result4",
                component: () => import("@/views/ggwpby/template/c4_result.vue"),
                meta: {title: '结果页面'}
            },
            {
                path: 'result_await3' + userKey,
                name: "result_await3",
                component: () => import("@/views/ggwpby/template/c3_result_await.vue"),
                meta: {title: '等待对手'}
            },
            {
                path: 'result_await4' + userKey,
                name: "result_await4",
                component: () => import("@/views/ggwpby/template/c4_result_await.vue"),
                meta: {title: '等待对手'}
            },
            {
                path: 'ert_end' + userKey,
                name: "ert_end",
                component: () => import("@/views/ggwpby/template/ert_end.vue"),
                meta: {title: '结束页面'}
            },
            {
                path: 'ert_reward' + userKey,
                name: "ert_reward",
                component: () => import("@/views/ggwpby/template/ert_reward.vue"),
                meta: {title: '奖励页面'}
            },
        ]
    },
]

export default router
