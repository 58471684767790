<template>
  <div>
    <!--<el-footer height="20">
      <el-progress :text-inside="true" :stroke-width="19" :percentage="$store.state.progress.progressIndex" ></el-progress>
    </el-footer>-->
  </div>
</template>

<script>
export default {
  name: "Footer",
  mounted() {

  }
}
</script>

<style scoped>
/* 进度条 浮在最底下 */
.el-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
::v-deep .el-progress-bar__outer {
  border-radius: 0;
}
::v-deep .el-progress-bar__inner {
  border-radius: 0;
}
.el-footer {
  padding: 0;
}
</style>
