<template>
    <div>
    </div>
</template>
<script>
    export default {
        data() {
            return {};
        },
        watch: {},
        computed: {},
        mounted() {
            this.$modal.loading("页面加载中");
            this.$nextTick(_ => {
                setTimeout(() => {
                    this.$modal.closeLoading();
                    this.$emit("toNextPage")
                }, 1500)
            })
        },
        methods: {
        }

    }
</script>
<style lang="scss" scoped>
    .box-card {
        width: 70%;
        position: relative;
        left: 50%;
        margin-left: -34%;
        background-color: #eeeeee;

        .el-col {
            font-size: 18px;
            line-height: 40px;
        }
    }
</style>
