import  request from "@/utils/request"

// 登录方法（正式实验去掉）
export function login(query) {
    return request({
        url: '/website/login',
        method: 'get',
        params: query
    })
}
// 登录验证方法
export function checkLogin(data) {
    return request({
        url: '/website/checkLogin',
        method: 'post',
        params: data
    })
}
// 获取用户信息
export function getUserInfo() {
    return request({
        url: '/website/getUserInfo',
        method: 'get',
    })
}

export function logout() {
    return request({
        url: '/website/logout',
        method: 'get',
    })
}
