<template>
    <div>
        <el-row style="display: flex; justify-content: space-around;">
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">(你)本轮收益：</div>
                <div class="tabclass_db">{{ roundProfitAll }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮从公共账户中获益：</div>
                <div class="tabclass_db">{{ roundProfit }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮个人账户剩余：</div>
                <div class="tabclass_db">{{ noInvestorNumber }}</div>
            </div>
        </el-row>
        <el-row class="center">
            <el-col :span="24">
                <h2 class="fons22">第<span class="colorf00">{{ roundSizeTemp }}</span>轮</h2>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content Textc"><p>所有玩家选择完毕！</p>
                    <p>每名玩家可从本轮公共投资中获得：<span class="fontRed">{{roundProfit}}</span>个代币</p>
                    <p>本轮小组成员向公共账户投放代币的情况以及对应的星级信息如下：</p></div>
            </el-col>
        </el-row>
        <el-row style="display: flex; justify-content: space-around; align-content: center">
            <el-col :span="24" class="Star" style="width: 400px">
                <el-row class="header">
                    <el-col :span="6">星级排名</el-col>
                    <el-col :span="6">代号</el-col>
                    <el-col :span="6">星级数量</el-col>
                    <el-col :span="6">投放代币数</el-col>
                </el-row>
                <el-col :span="24" class="main">
                    <el-row v-for="(item, index) in rankList" :key="index" style="text-align: center">
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.ranking }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.userGameName }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.starsCount }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.investorNumber }}个代币</el-col>
                    </el-row>
                </el-col>
            </el-col>
        </el-row>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapGetters, mapMutations, mapActions, mapState} = createNamespacedHelpers('ggwpby')
    import {styleFunc} from "@/utils/result";
    import Footer from "@/views/ggwpby/components/Footer";

    export default {
        components: {Footer},
        data() {
            return {
                active: false,
                roundSizeTemp: 1,
                roundProfitAll: 0,
                roundProfit: 0,
                noInvestorNumber: 0,
                rankList: [
                    {ranking: 1, userGameName: '玩家A', investorNumber: 10, starsCount: 10},
                    {ranking: 2, userGameName: '玩家C', investorNumber: 8, starsCount: 8},
                    {ranking: 3, userGameName: '你（玩家B）', investorNumber: 8, starsCount: 8},
                    {ranking: 4, userGameName: '玩家D', investorNumber: 4, starsCount: 4},
                ],
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyResult', 'ggwpbyRefereeDto', 'dataJson', 'syncDataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            this.load();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            styleFunc,
            ...mapGetters(['getPage']),
            ...mapMutations(['setDataJson', 'nextResult']),
            ...mapActions(['pushDataList']),
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                this.roundSizeTemp = this.ggwpbyRefereeDto.roundSize + 1;

                this.rankList = this.syncDataJson.resultDataList;
                this.noInvestorNumber = this.dataJson.noInvestorNumber;
                this.roundProfitAll = this.dataJson.roundProfitAll
                this.roundProfit = this.dataJson.roundProfit

                this.$store.dispatch("sleepTime");
            },
            next() {
                this.$store.dispatch("butDisable");

                //目前的c2结果点击时间没存
                this.dataJsonTemp['clickTime'] = this.nowTime;

                this.$emit("getNextPage", this.dataJson);
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }
    }
</script>
<style lang="scss" scoped>

    .header {
        text-align: center;
    }

    .footer {
        position: fixed;
        bottom: 20px;
        margin: 0 auto;
        width: 97.25%;
    }

    .grid-content {
        margin-top: 20px;
        line-height: 40px;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .fons22 {
        font-size: 22px;
        text-align: center;
        margin-top: 30px;
    }
    .colorf00 {
        color: #ff0000;
    }
</style>
