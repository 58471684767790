import  request from "@/utils/request"

// 开始填写
export function startQuestion() {
    return request({
        url: '/ggwpby/question/startQuestion',
        method: 'get',
    })
}

// 提交答案
export function publishAnswerList(data) {
    return request({
        url: '/ggwpby/question/publishAnswerList',
        method: 'post',
        data: data
    })
}
