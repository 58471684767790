<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  components: {},
  created() {
    // 禁用F12
    // this.disableF12();
  },
  methods: {
    // 禁用F12
    disableF12() {
      this.$nextTick(() => {
        // 禁用右键
        document.oncontextmenu = new Function('event.returnValue=false')
        // 禁用选择
        document.onselectstart = new Function('event.returnValue=false')
        //禁止f12
        document.οnkeydοwn = new Function('event.returnValue=false')
      })
      // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
      document.onkeydown = function (e) {
        if (e && e.keyCode === 123) {
          e.returnValue = false
          // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
          return false
        }
      }
    },
  }
}
</script>
<style>
html,body,#app{
  height: 100%;
  background-color: #eeeeee;
  color: #333;
}
</style>
