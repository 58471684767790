<template>
    <div>
        <div class="box-card">
            <el-row>
                <el-col :span="24" class="fontwb" style="font-size: 18px ;">阅读理解题</el-col>
                <el-col :span="24" class="lh40 mart15" style="font-size: 18px ;">为了确保你已经完全理解{{ertName}}的规则，请回答下列问题。</el-col>
                <el-col :span="24" class="lh40" style="font-size: 18px ;">（如回答错误，系统会要求你重新阅读指导语并再次回答问题。<span
                    class="TextColorE99313">全部答对</span>才能进入游戏环节。）
                </el-col>
            </el-row>
            <el-row v-for="(item, index) in problemList" :key="index">
                <el-col v-if="item.type === '1'" class="lh40 mart15" :span="24">
                    <div style="float: left; font-size: 18px">{{ item.num }}、<span v-html="item.problem"></span>
                        <el-input style="display: inline" v-model="item.answer" min="0"></el-input>
                        {{ item.fix }}
                        <span style="margin-left: 20px;color: red" v-if="item.errorFlag">{{ item.errorText }}</span>
                    </div>
                </el-col>

                <el-col v-if="item.type === '2'" class="lh40 mart15" :span="24">
                    <el-row>
                        <el-col class="lh40 mart15" :span="24">{{ item.num }}、<span v-html="item.problem"></span>
                            <span style="margin-left: 20px;color: red" v-if="item.errorFlag">{{ item.errorText }}</span>
                        </el-col>
                        <el-col class="lh40" v-for="(opt, i) in item.options" :key="i" :span="24">
                            <el-radio style="text-indent: 2em" v-model="item.answer" :label="opt.letter">
                                <span>{{ opt.letter }}.{{ opt.option }}</span>
                            </el-radio>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer>
            <el-button class="fontwb" @click="goBack" type="primary" v-if="$store.state.sleep.currentTime <= 0"
                       :disabled="$store.state.butDisabled.clickCountDown > 0">
                <span>{{ $t('situation.btn6') }}</span>
            </el-button>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
import Footer from "@/views/ggwpby/components/Footer";
import {createNamespacedHelpers} from 'vuex'
import {getUserKey} from "@/utils/auth";

const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('ggwpby')
export default {
    components: {Footer},
    data() {
        return {
            peopleCount: 3,
            ertName: "公共物品博弈",
            problemList: [
                {
                    id: 1,
                    num: '1',
                    problem: '在本游戏中，每个小组由几位玩家组成？',
                    answer: '',
                    errorFlag: false,
                    errorText: '',
                    correctAnswer: '4',
                    type: '1',
                },
                {
                    id: 2,
                    num: '2',
                    problem: '在本游戏中，每轮与你匹配是<span id="oppoUserCount">3</span>名相同的玩家吗？',
                    answer: '',
                    errorFlag: false,
                    errorText: '',
                    correctAnswer: 'A',
                    type: '2',
                    options: [{letter: 'A', option: '是'}, {letter: 'B', option: '否'}]
                },
                {
                    id: 3,
                    num: '3',
                    problem: '每轮游戏开始前，系统会向小组内的每位玩家发放多少代币？',
                    answer: '',
                    errorFlag: false,
                    errorText: '',
                    correctAnswer: '10',
                    type: '1',
                },
                {
                    id: 4,
                    num: '4',
                    problem: '假如在某轮游戏中，玩家A,B,C,D分别投放了4、6、8、2个代币。那么，本轮游戏中玩家A<span class="TextColorE99313">总共</span>能获得多少代币（公共账户收益+未投放代币数）？',
                    answer: '',
                    errorFlag: false,
                    errorText: '',
                    correctAnswer: '20',
                    type: '1',
                },
            ],
            dataJsonTemp: {}
        };
    },
    computed: {
        ...mapState(['config', 'ggwpbyRefereeDto', 'dataJson']),
        nowTime() {
            return this.$store.getters.getNowTime;
        }
    },
    mounted() {
        this.load();
        this.$nextTick(_ => {
            this.computeAnswer();
        });

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            ...mapGetters(['getPage']),
            ...mapActions(['pushDataList']),
            ...mapMutations(['setDataJson']),
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                let page = this.getPage();
                this.html = page.dataJson;
                let ruleParamJsonDto = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto;
                this.ertName = ruleParamJsonDto.ertName;
                this.$store.dispatch("sleepTime");
            },
            goBack() {
                this.$store.dispatch("butDisable");
                this.$store.state.page.pageIndex -= 2;
                // 准备跳下一页， pageIndex + 1
                this.$store.commit("setPageIndex");
                // 跳转下一页面的路由方法
                let configPage = this.getPage();
                this.$router.push({path: configPage.tempPath + "/" + getUserKey(), replace: true})
            },
            next() {
                this.$store.dispatch("butDisable");
                this.checkAnswer((answerList) => {
                    // 提交当前页面数据：并跳转下一页面
                    this.dataJsonTemp['clickTime'] = this.nowTime;

                    //答案、用户答案、是否正确
                    this.dataJsonTemp["problemAnswer"] = null;
                    this.dataJsonTemp["problemUserAnswer"] = answerList;
                    this.dataJsonTemp["problemResult"] = true;

                // 将数据存储到全局变量dataJson中
                // this.setDataJson(this.dataJsonTemp);
                this.pushDataList({dataJson: this.dataJsonTemp, opType: 2});
                this.$emit("commitData", (res) => {
                    this.$emit("getNextPage", this.dataJson);
                })
            })
        },
        checkAnswer(success) {
            let answerList = [];
            let ertMsg = "";
            for (let i = 0; i < this.problemList.length; i++) {
                let answer = this.problemList[i].answer;
                if (answer.toString().trim() !== this.problemList[i].correctAnswer.toString()) {
                    this.problemList[i].errorFlag = true;
                    this.problemList[i].errorText = "回答错误";
                    ertMsg += (i + 1) + "、"
                }else{
                    this.problemList[i].errorFlag = false;
                    answerList.push(answer)
                }
            }
            if (answerList.length === this.problemList.length) {
                success(answerList)
            }else{
                this.$modal.msgClose();
                this.$modal.msgWarning("第" + ertMsg.slice(0,-1) + "题回答错误");
                return;
            }
        },
        //计算答案
        computeAnswer() {
            document.getElementById("oppoUserCount").innerText = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto.oppoUserCount;
            let ggwpbyRefereeDto = this.ggwpbyRefereeDto;
            //当前轮次-回报率
            let returnRate = ggwpbyRefereeDto.ggwpbyRandomDto.returnRate;
            let initialCoinCount = ggwpbyRefereeDto.ggwpbyRandomDto.initialCoinCount;
            this.problemList[0].correctAnswer = ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto.groupUserCount
            this.problemList[2].correctAnswer = initialCoinCount

                this.problemList[3].correctAnswer = (parseInt(this.problemList[3].correctAnswer) * parseInt(returnRate) / 4) + (parseInt(initialCoinCount) - 4)

            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {
                    //测试计算通过
                    this.problemList.forEach(problem => {
                        problem.answer = problem.correctAnswer;
                    });

                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .disflex {
        display: flex;
    }

    ::v-deep .el-input__inner {
        border: 0;
        width: 100px;
        font-size: 14px;
        background-color: #eeeeee;
        border-radius: 0;
        border-bottom: 1px solid #03628e;
    }

    .box-card {
        width: 70%;
        //min-height: 500px;
        position: relative;
        left: 50%;
        margin-left: -35%;
        //border: 1px solid #4b98b7;
        .lh40 {
            line-height: 35px;
            letter-spacing: 2px;
            font-size: 18px;
        }

        .mart15 {
            margin-top: 15px;
        }

        ::v-deep .el-radio__label {
            font-size: 18px;
        }
    }
</style>
