<template>
  <div>
    <el-row class="footer">
      <el-col class="Textc">
        <slot></slot>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index",
  watch: {

  },
  methods: {

  }
}
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 30px;
  margin: 0 auto;
  width: 97.25%;
}

</style>
