<template>
    <div>
        <br/>
        <el-row class="text-left">
            <el-col :offset="4" id="detail" :span="16" v-html="html">

            </el-col>
        </el-row>
        <br/><br/><br/><br/>
        <Footer>
            <el-button @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('ggwpby')
    import Footer from "@/views/ggwpby/components/Footer"
    import {transformDecimal} from "@/utils/result";

    export default {
        components: {Footer},
        data() {
            return {
                html: null,
                time: 10,
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyRefereeDto', 'dataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            // 页面加载方法
            this.load()
            this.$nextTick(() => {
                this.replaceTemp()
            });

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            ...mapGetters(['getPage']),
            ...mapActions(['pushDataList']),
            ...mapMutations(['setDataJson']),
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                let page = this.getPage();
                this.html = page.dataJson;
                this.$store.dispatch("sleepTime", true);
            },
            // 继续按钮
            next() {
                this.$store.dispatch("butDisable");
                this.dataJsonTemp['clickTime'] = this.nowTime;
                // 将数据存储到全局变量dataJson中
                this.setDataJson(this.dataJsonTemp);
                this.pushDataList({dataJson: this.dataJson, opType: 1});
                this.$emit("commitData", (res) => {
                    this.$emit("getNextPage", this.dataJson);
                })
            },
            //指导语页面变量替换
            replaceTemp() {
                //替换指导语显示的内容
                let ruleParamJsonDto = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto;
                ruleParamJsonDto.casting = '玩家A ~ 玩家P'
                let starsRatio = ruleParamJsonDto.starsRatio;
                let starLevel = 1;
                let twoStarLevel = 2;
                let highestStarLevel = ruleParamJsonDto.initialCoinCount;
                if (starsRatio) {
                    let starsRatioSplit = starsRatio.split(",");
                    if (starsRatioSplit.length === 2) {
                        starLevel = transformDecimal(parseFloat(starsRatioSplit[0]), starsRatioSplit[1]);
                        twoStarLevel = transformDecimal(parseFloat(starLevel) * 2, starsRatioSplit[1]);
                        highestStarLevel = transformDecimal(parseFloat(starLevel) * parseFloat(ruleParamJsonDto.initialCoinCount), starsRatioSplit[1]);
                    }
                }
                //计算星级比例
                ruleParamJsonDto.starLevel = starLevel;
                ruleParamJsonDto.twoStarLevel = twoStarLevel;
                ruleParamJsonDto.highestStarLevel = highestStarLevel;
                if (this.html == null) return null;
                for (const key in ruleParamJsonDto) {
                    if (ruleParamJsonDto[key] == null) {
                        continue;
                    }
                    let value = ruleParamJsonDto[key];
                    this.html = this.html.replaceAll("{{" + key + "}}", value)
                }
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                setTimeout(() => {

                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }
    }
</script>
<style lang="scss" scoped>

    #detail {
        font-size: 18px;
        line-height: 40px;
    }
</style>
