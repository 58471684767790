<template>
  <div>
    <Common>
      <router-view />
    </Common>
  </div>
</template>

<script>
import Common from "../common"

export default {
  components: {Common},
  name: "index",
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
  beforeDestroy() {

  },
}
</script>
<style lang="scss" scoped>

</style>

