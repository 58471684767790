import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'

import './permission' // permission toolbutton

//多语言
import VueI18n from "vue-i18n"

import i18n from "./language"
//全局样式
import "./style/index.scss"

import modal from "@/utils/modal";
//禁止回退
window.addEventListener('popstate', function () {
    history.pushState(null, null, document.URL)
})

// 模态框对象
Vue.prototype.$modal = modal

Vue.config.productionTip = false

Vue.use(ElementUI, {locale});
Vue.use(VueI18n)


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')

