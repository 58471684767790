<template>
  <div>
    <!--<el-select v-model="active_color" placeholder="" @change="active">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      active_color: '0',
      options: [
        {
          value: '0',
          label: '深邃蓝'
        },
        {
          value: '1',
          label: '简朴白'
        },
      ],
    };
  },
  mounted() {
    this.active()
  },
  methods: {
    active() {
      let app = document.getElementsByTagName('body')[0].style
      if (this.active_color === '1') {
        app.setProperty('--StarColor1', '#eee');
        app.setProperty('--DBColor1', '#000');
        app.setProperty('--bgcColorText', '#fff');
        app.setProperty('--TextColor1', '#000');
        app.setProperty('--bgcColor10', '#fff');
        app.setProperty('--BorderColor0', '#000');
        app.setProperty('--BorderColor1', '#000');
        app.setProperty('--bgcColor', '#fff');
        app.setProperty('--buttonColor', '#000');
        app.setProperty('--buttonColor1', '#E8E8E8');
        app.setProperty('--lineColor1', '#000');
        app.setProperty('--network', '#1E90FF');
      } else {
        app.setProperty('--StarColor1', '#4b98b7');
        app.setProperty('--DBColor1', '#63bfe9');
        app.setProperty('--bgcColorText', '#03628e');
        app.setProperty('--TextColor1', '#fff');
        app.setProperty('--bgcColor10', '#1099d2');
        app.setProperty('--BorderColor0', '#000');
        app.setProperty('--BorderColor1', '#4A97B6');
        app.setProperty('--bgcColor', '#03628e');
        app.setProperty('--buttonColor', '#03628e');
        app.setProperty('--buttonColor1', '#a2c9d9');
        app.setProperty('--lineColor1', '#a2c9d9');
        app.setProperty('--network', '#10fe01');
      }
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
