import router from './router'
import store from './store'
import { Message } from 'element-ui'
import {getToken, getUserKey, removeExpKey, removeToken, removeUserKey} from '@/utils/auth'

const whiteList = ["404", '/', "401", '/redirect', '/mobile', '/weixin'];
//全局路由守卫
router.beforeEach((to, from, next) => {
  store.state.title = to.meta.title

  const isLogin = getToken()
  const key = to.params.userKey;
  const userKey = getUserKey();
  // console.log(to.path)

  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    // 如果去实验中心需要先登录
    if (isLogin) {
      if (userKey !== key) {
        store.dispatch('LogOut').then((err) => {
          Message({ message: "用户标识不一致", type: 'error', duration: 5 * 1000 })
          next({ path: '/' })
        })
      }
      // 刷新
      if (!store.state.vpeUser.id) {
        store.dispatch('GetInfo').then(() => {
          next()
        }).catch(() => {
          store.dispatch('LogOut').then((err) => {
            Message.error(err)
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
    } else {
      next({ path: "/" })
    }
  }

})
