module.exports = {
    language: {
        name: "English"
    },
    situation: {
        btn: 'I have confirmed the agreement',
        btn1: 'I have known',
        btn2: 'Cancel the party',
        btn3: 'YES',
        btn4: 'NO',
        btn5: 'continue',
        btn6: 'Return instructions',
    },
    login: {
        userTip: "EnglishLogin",
        registerFree: "register",
        forget: "Forget Password"
    },
    matching: {
        text: 'Welcome to the first stage of the decision game Before the start of this phase, you (16 participants) will be divided into 4 groups of 4. Players in the same group will complete two rounds of decision-making game together. Before the start of each round of the game, 10 tokens will be issued to each person. You need to decide at the same time how much of the 10 tokens to take and put into the "public account", and the remaining tokens will be accumulated into your personal account for your personal ownership. After all the members put in, the total amount in the "public account" will be doubled and divided equally among each member. Therefore, putting tokens into public accounts is beneficial to all members, and the money kept in personal accounts is only beneficial to yourself. For example, if all four players put 5 token direct public accounts, they will benefit from the public accounts ((5+5+5+5) *2/4) tokens respectively. Your income in each round consists of both your personal account and the number of tokens that benefit from your public account. Revenue per round = 10- your investment amount + everyone\'s investment amount *2/4 from the end of each round, you can see the number of other players\' investment and your current round\'s revenue.',
        awaitButton: 'Waiting Member',
        startButton: 'Start Experiment',
        finishButton: 'Experiment completed',
        dialog: 'Matching in progress！',
        dialog2: 'Click the Cancel button to stop platooning',
        notStart: 'not start',
        notFinish: "not completed",
        finish: 'Experiment completed',
    }
}
