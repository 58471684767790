import Vue from 'vue'
import Vuex from 'vuex'

import ggwpby from "./modules/ggwpby"
import odgm from "@/store/modules/odgm";
import {getUserInfo, logout} from "@/api/login";
import {removeExpKey, removeToken, removeUserKey} from "@/utils/auth";
import createVuexAlong  from 'vuex-along'

Vue.use(Vuex)

export default new Vuex.Store({
  // 平时存的数据
  state: {
    title: '',
    matching: 0, //进度
    vpeUser: {},
    noteCode: null,
    // 网络状态
    net: {
      speed: "检测中",
      color: 'gray',
    },
    // 时间
    time: {
      serverTime: new Date().valueOf(),
      clientTime: new Date().valueOf()
    },
    // 鼠标轨迹
    mouseList: [],
    // 长连接
    socket: null,
    // 页面
    page: {
      pageCount: 0,
      pageIndex: 0,
    },
    // 进度条
    progress: {
      progressIndex: 0,
    },
    // 指导语休息时间
    sleep: {
      // 指导语-最大休息时间（秒）
      infoReadingTime: 0,
      // 通用-最大休息时间（秒）
      btnReadingTime: 0,
      // 出现倒计时的时间（秒）[未用到]
      countdownTime: 20,
      // 当前显示时间（秒）
      currentTime: 0,
      times: null,
    },
    //按钮点击后禁用时间
    butDisabled: {
      //按钮点击后禁用时间
      butDisabledTime: 2,
      //按钮点击后释放禁用时间
      clickCountDown: 0,
      times: null,
    }
  },
  getters: {
    // 获取网速
    getNet(state) {
      return state.net
    },
    // 获取当前时间
    getNowTime(state) {
      return state.time.serverTime + (new Date().valueOf() - state.time.clientTime);
    },
    // 获取socket
    getSocket(state) {
      return state.socket;
    },
  },
  mutations: {
    // 设置网速
    setSpeed(state, net) {
      state.net = {... net}
    },
    // 设置当前时间
    setNowTime(state, nowTime) {
      state.time.serverTime = nowTime;
      state.time.clientTime = new Date().valueOf();
    },
    // 设置socket
    setSocket(state, socket) {
      state.socket = socket;
    },
    // 销毁socket数据
    destroySocket(state) {
      if (state.socket) {
        state.socket.close();
        state.socket = null;
      }
    },
    // 下一页方法
    setPageIndex(state) {
      state.page.pageIndex += 1;

      // 计算进度条进度 pageIndex 是从0开始，进度条需要+1后计算进度
      let progressIndex = Math.round((state.page.pageIndex + 1) * 1000 / state.page.pageCount) / 10;
      // 计算进度条进度
      if (progressIndex > 100) {
        state.progress.progressIndex = 100;
      } else {
        state.progress.progressIndex = progressIndex;
      }
    },
  },
  actions: {
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          state.vpeUser = res.data;
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          removeExpKey()
          removeToken()
          removeUserKey()
          resolve()
          window.clearVuexAlong(true, true)
          window.location.href = "/";
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 调用指导语倒计时
    sleepTime({state}, infoState = false) {
      // 开始休息时间定时器，当前时间等于最大休眠时间
      state.sleep.currentTime = infoState ? state.sleep.infoReadingTime : state.sleep.btnReadingTime;
      // 倒计时
      state.sleep.times = setInterval(() => {
        state.sleep.currentTime--; //递减
        if (state.sleep.currentTime <= 0) {
          // <=0 变成获取按钮
          clearInterval(state.sleep.times);
        }
      }, 1000); //1000毫秒后执行
    },
    butDisable({state}){
      state.butDisabled.clickCountDown = state.butDisabled.butDisabledTime;
      // 倒计时
      state.butDisabled.times = setInterval(() => {
        state.butDisabled.clickCountDown--; //递减
        if (state.butDisabled.clickCountDown <= 0) {
          // <=0 变成获取按钮
          clearInterval(state.butDisabled.times);
        }
      }, 1000); //1000毫秒后执行
    }
  },
  modules: {
    ggwpby,
    odgm
  },
  plugins: [createVuexAlong({
    name: "GameStudy",
    local: {
      list: ["socket", "mouseList"],
      // 过滤模块 list里 数据， 将其他的存入 localStorage
      isFilter: true,
    },
    session: {
      list: ["socket", "mouseList"],
      // 过滤模块 list里 数据， 将其他的存入 sessionStorage
      isFilter: true,
    },
    justSession: true,
  })]
})
