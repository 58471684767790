<template>

</template>

<script>
import io from "socket.io-client"

export default {
  name: "socket",
  props: {
    resultId: {
      type: Number,
      default: null
    },
    roomId: {
      type: String,
      default: null,
    }
  },
  mounted() {
    let query = {
      userId: this.$store.state.vpeUser.id,
      resultId: this.resultId,
      roomId: this.roomId
    }
    this.createSocket(query)
  },
  methods: {
    // 长连接
    createSocket(query) {
      let stateSocket = this.$store.getters.getSocket;
      if (stateSocket) {
        return
      }
      this.$modal.loading("正在连接服务器...");
      const socket = io.connect(`${process.env.VUE_APP_INVESTOR_API}`, {
        reconnectionDelayMax: 10000,
        transports: ['websocket'], // 此项必须设置
        query: query
      });
      //监听与服务器的连接状态
      socket.on("connect", (message) => {
        this.$modal.closeLoading()
      })
      //监听重连状态
      socket.on("reconnect", () => {
        this.$modal.closeLoading();
        this.$modal.msgSuccess("连接成功")
      })
      socket.on("disconnect", () => {
        this.$modal.loading("您的电脑与服务器断开连接，请检查网络是否正常或者更换网络。")
      })
      socket.on("stop", (message) => {
        this.$modal.alertError(message, (action) => {
          location.href = '/'
        })
      })
      //同步服务端时间
      socket.on('now_time', (response) => {
        this.$store.commit("setNowTime", response)
      })
      this.$store.commit("setSocket", socket)
    },
  },
  beforeDestroy() {
    this.$modal.closeLoading();
    this.$store.commit("destroySocket");
  },
}
</script>

<style scoped>

</style>
