<template>
    <div>
        <el-row v-if="moneyStr" style="margin-bottom: 30px;font-size: 18px;">
            <el-col :offset="3" :span="18" v-html="moneyStr">
            </el-col>
        </el-row>
        <el-row v-for="(item, index) in list" :key="index" style="margin-bottom: 30px">
            <el-col :offset="3" :span="18">
                <el-card class="box-card">
                    <el-row style="line-height: 70px">
                        <el-col :span="20">
                            <el-row style="line-height: 40px">
                                <el-col :span="24">
                                    <div class="grid-content bg-purple-dark text">{{ item.name }}</div>
                                </el-col>
                                <el-row>
                                    <el-col :span="5">
                                        <!--<div class="grid-content bg-purple time"><i class="el-icon-time"></i>&nbsp;{{
                                            item.time
                                            }}
                                        </div>-->
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="grid-content bg-purple-light time"
                                             v-if="index === 0 && item.type === 1">
                                            <i class="el-icon-circle-close"></i>&nbsp;{{ $t('matching.notQuestion') }}
                                        </div>
                                        <div class="grid-content bg-purple-light time" v-else-if="item.type === 1">
                                            <i class="el-icon-circle-close"></i>&nbsp;{{ $t('matching.notStart') }}
                                        </div>
                                        <div class="grid-content bg-purple-light time" v-else-if="item.type === 2 || item.type === 0">
                                            <i class="el-icon-circle-close"></i>&nbsp;{{ $t('matching.notFinish') }}
                                        </div>
                                        <div class="grid-content bg-purple-light time" v-if="item.type === 3">
                                            <i class="el-icon-circle-check"></i>&nbsp;{{ $t('matching.finish') }}
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-row>
                        </el-col>
                        <el-col :span="4">
                            <div class="grid-content bg-purple-light">
                                <!--问卷-->
                                <el-button class="" style="color: #ffffff;" v-if="index === 0"
                                           @click="startQuestion(item.id)" :disabled="item.type === 3" type="primary">
                                    {{ $t(item.type === 1 ? 'matching.startQuestion' : 'matching.finishButton') }}
                                </el-button>
                                <div v-else-if="index > 0">
                                    <!--等待开始-->
                                    <el-button v-if="item.type === 0" style="color: #ffffff;" @click="await ()"
                                               :disabled="[0, 1, 2].indexOf(list[index - 1].type) !== -1 || item.type === 3"
                                               type="primary">
                                        {{ $t('matching.awaitButton') }}
                                        <br/><br/>
                                        <span style="font-size: 8px; color: #31ff62">点击刷新</span>
                                    </el-button>
                                    <!--开始实验-->
                                    <el-button v-if="item.type === 1" style="color: #ffffff;" @click="matching(item.id)"
                                               :disabled="[0, 1, 2].indexOf(list[index - 1].type) !== -1 || item.type === 3"
                                               type="primary">
                                        {{ $t('matching.startButton') }}
                                    </el-button>
                                    <!--继续实验-->
                                    <el-button v-else-if="item.type === 2" style="color: #ffffff;"
                                               @click="continueTask(item.id)"
                                               :disabled="[0, 1, 2].indexOf(list[index - 1].type) !== -1 || item.type === 3"
                                               type="primary">
                                        {{ $t('matching.continueButton') }}
                                    </el-button>
                                    <!--完成实验-->
                                    <el-button v-else-if="item.type === 3" style="color: #ffffff;"
                                               :disabled="[0, 1, 2].indexOf(list[index - 1].type) !== -1 || item.type === 3"
                                               type="primary">
                                        {{ $t('matching.finishButton') }}
                                    </el-button>
                                    <!--中途退出-->
                                    <el-button v-else-if="item.type === 4" style="color: #ffffff;"
                                               :disabled="[0, 1, 2].indexOf(list[index - 1].type) !== -1 || item.type === 3"
                                               type="primary">
                                        {{ $t('matching.causeButton') }}
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" :show-close="showClo" width="30%">
            <el-row style="text-align: center; line-height: 40px;">
                <el-col :span="24"><span><i class="el-icon-loading" style="color: #4b98b7;font-size: 18px"/>{{
                        $t('matching.dialog')
                }}</span>
                </el-col>
                <el-col :span="24"><span>{{ $t('matching.dialog2') }}</span></el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="stopMatchTeam">{{ $t('situation.btn2') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import {getUserKey} from "@/utils/auth";
    import {matchRoom, queryTeam, queryWait, stopMatchTeam, tasklist} from "@/api/ggwpby/match";
    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations, mapGetters, mapState} = createNamespacedHelpers('ggwpby')

    export default {
        name: 'matching',
        data() {
            return {
                dialogVisible: false,
                showClo: false,
                timer: null,
                ertId: null,
                moneyStr: null,
                interval: null,
                list: [
                    {
                        id: 0,
                        name: '问卷调查',
                        time: '2022-05-15 14:41:33',
                        type: 0
                    },
                ]
            };
        },
        mounted() {
            this.getTaskList();
            this.queryWait();
            this.timeout()
        },
        methods: {
            ...mapMutations([
                "setUserInfo"
            ]),
            timeout() {
                this.interval = setInterval(() => {
                    this.getTaskList();
                    this.$modal.msgSuccess("已为您自动更新实验列表！")
                }, 1000 * 60 * 3);

            },
            // 查询实验列表
            getTaskList() {
                tasklist().then(res => {
                    this.list.splice(1, this.list.length);
                    // 第一个实验是问卷
                    this.list[0].type = res.questionState ? 3 : 1;
                    let ertStateDtoList = [...res.ertStateDtoList];
                    let stopTimer = true;
                    // 后续实验的填充
                    ertStateDtoList.forEach(ert => {
                        let task = {
                            id: ert.id,
                            name: ert.ertName,
                            time: ert.createTime,
                            type: ert.type
                        };
                        this.list.push(task)
                        if (ert.type == 0) {
                            stopTimer = false;
                        }
                    })
                    this.moneyStr = res.moneyStr

                    if (stopTimer){
                        clearInterval(this.interval)
                    }
                })
            },
            // 开始填写问卷
            startQuestion(ertId) {
                this.$router.push("/common/question/" + getUserKey());
                clearInterval(this.interval)
            },
            // 查询是否有正在组队的任务
            queryWait() {
                queryWait().then(res => {
                    if (res.data) {
                        this.dialogVisible = true;
                        this.queryTeam(res.data);
                    }
                })
            },
            // 开始组队
            matching(ertId) {
                this.ertId = ertId;
                matchRoom(ertId).then(res => {
                    if (res.code === 301) {
                        this.$modal.msgError(res.msg);
                        return
                    }
                    this.dialogVisible = true
                    this.queryTeam(ertId);
                })
            },
            // 等待组队
            await() {
                // 清除定时器
                this.clearTimeout();
                this.$modal.msgClose();
                this.$modal.msgSuccess("已为您刷新状态！")
                this.getTaskList();
                this.timer = setTimeout(() => {
                    this.$modal.msgClose();
                    this.$modal.msgWarning("实验列表每3分钟会自动更新状态，请勿频繁点击刷新！")
                }, 2000)
            },
            // 查询组队情况
            queryTeam(ertId) {
                this.ertId = ertId
                queryTeam({ertId: ertId}).then(res => {
                    if (res.data) {
                        let data = {roomId: res.data, resultId: res.resultId, userId: res.userId};
                        this.setUserInfo(data)
                        // 清除定时器
                        this.clearTimeout();

                        this.$modal.loading("组队成功，正在初始化，请稍等")
                        // 随机1-5秒，跳转实验正式页面，减轻并发压力
                        setTimeout(() => {
                            this.$modal.closeLoading();
                            this.$router.push({path: '/ggwpby/index/' + getUserKey()})
                            clearInterval(this.interval)
                        }, Math.floor(Math.random() * 5 + 1) * 1000)
                    } else {
                        // 没有组队成功，则继续查询
                        this.timer = setTimeout(() => {
                            this.queryTeam(ertId);
                        }, 5000);
                    }
                })
            },
            // 停止组队
            stopMatchTeam() {
                stopMatchTeam(this.ertId).then(res => {
                    this.dialogVisible = false
                    this.$modal.msgSuccess(res.msg);
                    // 清除定时器
                    this.clearTimeout();
                })
            },
            // 清除定时器
            clearTimeout() {
                if (this.timer) {
                    window.clearTimeout(this.timer);
                }
            },
            continueTask(ertId) {
                this.$modal.loading("正在获取组队信息")
                this.queryTeam(ertId);
                setTimeout(this.$modal.closeLoading, 10 * 1000);
            }
        },
        beforeDestroy() {
            if (this.timer) {
                window.clearTimeout(this.timer)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .box-card {
        .text {
            //  color: red;
            font-size: 16px;
            font-weight: bold;
        }

        .time {
            color: #c6c6c6;
            line-height: 30px;
        }
    }
</style>
