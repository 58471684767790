import { render, staticRenderFns } from "./c4_result.vue?vue&type=template&id=bcc5c2ea&scoped=true&"
import script from "./c4_result.vue?vue&type=script&lang=js&"
export * from "./c4_result.vue?vue&type=script&lang=js&"
import style0 from "./c4_result.vue?vue&type=style&index=0&id=bcc5c2ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcc5c2ea",
  null
  
)

export default component.exports