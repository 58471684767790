import Vue from 'vue/dist/vue.min.js';
import VueI18n from 'vue-i18n/dist/vue-i18n';
import Cookies from 'js-cookie';

Vue.use(VueI18n);

// 存储在本地,刷新后不会丢失
const language = Cookies.get('language') || 'zh';
Cookies.set('language', language)
const i18n = new VueI18n({
    locale: language, //  语言标识
    messages: {
        zh: require('./config/zh'), // 中文
        en: require('./config/en'), // 英文
    }
});

export default i18n;