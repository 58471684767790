import {Message, MessageBox, Notification, Loading} from 'element-ui'

let loadingInstance;
let magInstance;
let alertInstance;
let notifInstance;

export default {
    // 消息提示 html
    msgHtml(content) {
        magInstance = Message.success({
            message: content,
            dangerouslyUseHTMLString: true,
            offset: 100,
        })
    },
    //---------------------------------------------------【msg】
    // 关闭消息提示
    msgClose() {
        if (magInstance) {
            magInstance.close();
        }
    },
    // 消息提示
    msg(content) {
        magInstance = Message.info(content)
    },
    // 错误消息
    msgError(content) {
        magInstance = Message.error(content)
    },
    // 成功消息
    msgSuccess(content) {
        magInstance = Message.success(content)
    },
    // 警告消息
    msgWarning(content) {
        magInstance = Message.warning(content)
    },

    //---------------------------------------------------【alert】
    // 关闭弹框
    alertClear() {
        alertInstance = null;
    },

    // 消息提示 html
    alertHtml(content, title, type, callback) {
        if (alertInstance !== content) {
            alertInstance = content;
            MessageBox.alert(content, title, {
                type: type,
                dangerouslyUseHTMLString: true, callback
            })
        }
    },
    // 弹出提示
    alert(content) {
        if (alertInstance !== content) {
            alertInstance = content;
            MessageBox.alert(content, "系统提示")
        }
    },
    // 错误提示
    alertError(content, callback) {
        if (alertInstance !== content) {
            alertInstance = content;
            MessageBox.alert(content, "系统提示", {type: 'error', callback})
        }
    },
    // 成功提示
    alertSuccess(content, callback) {
        if (alertInstance !== content) {
            alertInstance = content;
            MessageBox.alert(content, "系统提示", {type: 'success', callback})
        }
    },
    // 警告提示
    alertWarning(content, callback) {
        if (alertInstance !== content) {
            alertInstance = content;
            MessageBox.alert(content, "系统提示", {type: 'warning', callback})
        }
    },
    //---------------------------------------------------【notif】
    // 关闭通知
    notifClose() {
        if (notifInstance) {
            notifInstance.close();
        }
    },
    // 通知提示
    notify(content) {
        notifInstance = Notification.info(content)
    },
    // 错误通知
    notifyError(content) {
        notifInstance = Notification.error(content);
    },
    // 成功通知
    notifySuccess(content) {
        notifInstance = Notification.success(content)
    },
    // 警告通知
    notifyWarning(content) {
        notifInstance = Notification.warning(content)
    },
    // 确认窗体
    confirm(content) {
        return MessageBox.confirm(content, "系统提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
        })
    },
    // 打开遮罩层
    loading(content) {
        loadingInstance = Loading.service({
            lock: true,
            text: content,
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        })
    },
    // 关闭遮罩层
    closeLoading() {
        if (loadingInstance) {
            loadingInstance.close();
        }
    }
}
