<template>
  <el-container>
    <el-header class="bgc03">
      <div class="trapezoid">
        <div class="trapezoid_text" style="">{{ $store.state.title }}</div>
      </div>
      <el-row :gutter="20" style=" top: 10px;" type="flex"  justify="end">
        <el-col :span="3" :offset="18">
          <!-- //颜色切换 -->
          <color></color>
          <!-- //双语切换 -->
          <!-- <language></language> -->
        </el-col>
        <el-col :span="3">
          <span class="color1"> 网络状态：</span><span :style="{ color: net.color }">{{ net.speed }}</span>
        </el-col>
      </el-row>
    </el-header>
    <el-main>
      <slot></slot>
    </el-main>
  </el-container>
</template>

<script>
import color from "@/components/color";
import language from "@/components/language";

import "@/utils/speedtest/SpeedTest"
import "@/utils/MouseTracker";
import "@/utils/browser"
// import "@/utils/refresh";

export default {
  components: { language, color },
  name: "index",
  // 引用一个组件
  // setup(props,ctx){
  //   const movement = MouseMovement()
  //   return {movement};
  // },
  data() {
    return {
      color: '#1e90ff',
      value: "0",
      active_lang: '0',
      net: { speed: "检测中", color: "gray" }
    }
  },
  mounted() {
    setInterval(() => {
      this.net = this.$store.state.net;
    }, 5 * 1000)
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
// .switch {
//   width: 200px;
//   position: fixed;
//   top: 10px;
//   right: 200px;
// }

.network {
  width: 170px;
  position: fixed;
  top: 20px;
  right: 5px;
}

.el-header {
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.el-main {
  margin-top: 60px;
}

//.el-main {
//  background-color: #eeeeee;
//  color: #333;
//  //height: 88.9vh;
//  //height: 91.5vh;
//}
</style>

