import Vue from 'vue'
import VueRouter from 'vue-router'

import Ggwpby from "@/router/ggwpby"

import Common from "@/router/common"

Vue.use(VueRouter)

const routes = [
    ...Ggwpby,
    ...Common,
  {
    path: '',
    name: "404_index",
    component: (resolve) => require(['@/views/error/404'], resolve),
  },
  {
    path: '/404',
    name: "404",
    component: (resolve) => require(['@/views/error/404'], resolve),
  },
  {
    path: '/401',
    name: "401",
    component: (resolve) => require(['@/views/error/401'], resolve),
  },
  {
    path: '/mobile',
    name: "mobile",
    component: (resolve) => require(['@/views/error/mobile'], resolve),
  },
  {
    path: '/weixin',
    name: "weixin",
    component: (resolve) => require(['@/views/error/weixin'], resolve),
  },
  {
    path: '/redirect',
    name: "redirect",
    component: (resolve) => require(['@/views/redirect'], resolve),
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => {
    history.pushState(null, null, document.URL)
  },
  routes
})

export default router
