<template>
    <div style="background-color: #fff;">
        <header>
            <div style="background-color: #97d700"></div>
            <div style="background-color: #0071d1"></div>
            <div style="background-color:#00c9d0;"></div>
            <div style="background-color: #3d4576"></div>
        </header>
        <main>
            <h2 class="textc" style="font-size: 20px">决策问卷</h2>
            <div class="box_card_2 line-height">
                <P>在下一个任务中，请你想象自己与某个人（用TA表示）被随机分配在一起，你们彼此并不认识，将来也不会见面。你的所有选择将会完全保密。</P>
                <p>你将会在你和TA之间做出一系列关于分配资源的决策。对于下面的每个问题，请通过<span class="mainText">选择收益分配下面的按钮</span>来表明你最偏好的结果分配。每题只能选择一个。你的决策将会决定你和TA各自所得的金钱数
                </p>
                <P class="Lh40">在下面的例子中，某个人选择这种分配意味着他/她将得到50元，而对方得到40元。</P>
            </div>
            <h4 class="textc">例如：</h4>
            <div class="box_card textc">
                <div class="disflex">
                    <div class="w11">你得到</div>
                    <div class="w10">30</div>
                    <div class="w10">35</div>
                    <div class="w10">40</div>
                    <div class="w10">45</div>
                    <div class="w10">50</div>
                    <div class="w10">55</div>
                    <div class="w10">60</div>
                    <div class="w10">65</div>
                    <div class="w10">70</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w11">TA得到</div>
                    <div class="w10">80</div>
                    <div class="w10">70</div>
                    <div class="w10">60</div>
                    <div class="w10">50</div>
                    <div class="w10">40</div>
                    <div class="w10">30</div>
                    <div class="w10">20</div>
                    <div class="w10">10</div>
                    <div class="w10">0</div>
                </div>
                <div class="disflex">
                    <div class="w10">&#12288;</div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" checked type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                    <div class="w10"><label><input name="choose" disabled type="radio"/></label></div>
                </div>
            </div>
            <div class="partition"></div>
            <div class="box_card_2 line-height">
                <P> 结果没有正误之分，只是反映个人偏好。在你做出决策后，<span class="mainText">单击下方的按钮来选择相应的金钱分配</span>。如你所见，<u>你的选择会同时影响你和对方所得到的钱数。</u>
                </P>
            </div>
            <div class="box_card_2 textc">
                <span class="num">(1)</span>
                <div class="disflex">
                    <div class="w11">你得到</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                    <div class="w10">85</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w11">TA得到</div>
                    <div class="w10">85</div>
                    <div class="w10">76</div>
                    <div class="w10">68</div>
                    <div class="w10">59</div>
                    <div class="w10">50</div>
                    <div class="w10">41</div>
                    <div class="w10">33</div>
                    <div class="w10">24</div>
                    <div class="w10">15</div>
                </div>
                <div class="disflex">
                    <div class="w10">&#12288;</div>
                    <div class="w10"><label><input name="choose_0" value="85-85" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-76" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-68" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-59" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-50" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-41" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-33" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-24" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_0" value="85-15" type="radio"/></label></div>
                </div>
            </div>
            <div class="box_card textc">
                <span class="num">(2)</span>
                <div class="disflex">
                    <div class="w11">你得到</div>
                    <div class="w10">85</div>
                    <div class="w10">87</div>
                    <div class="w10">89</div>
                    <div class="w10">91</div>
                    <div class="w10">93</div>
                    <div class="w10">94</div>
                    <div class="w10">96</div>
                    <div class="w10">98</div>
                    <div class="w10">100</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w11">TA得到</div>
                    <div class="w10">15</div>
                    <div class="w10">19</div>
                    <div class="w10">24</div>
                    <div class="w10">28</div>
                    <div class="w10">33</div>
                    <div class="w10">37</div>
                    <div class="w10">41</div>
                    <div class="w10">46</div>
                    <div class="w10">50</div>
                </div>
                <div class="disflex">
                    <div class="w10">&#12288;</div>
                    <div class="w10"><label><input name="choose_1" value="85-15" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="87-19" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="89-24" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="91-28" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="93-33" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="94-37" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="96-41" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="98-46" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_1" value="100-50" type="radio"/></label></div>
                </div>
            </div>
            <div class="box_card textc">
                <span class="num">(3)</span>
                <div class="disflex">
                    <div class="w11">你得到</div>
                    <div class="w10">50</div>
                    <div class="w10">54</div>
                    <div class="w10">59</div>
                    <div class="w10">63</div>
                    <div class="w10">68</div>
                    <div class="w10">72</div>
                    <div class="w10">76</div>
                    <div class="w10">81</div>
                    <div class="w10">85</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w11">TA得到</div>
                    <div class="w10">100</div>
                    <div class="w10">98</div>
                    <div class="w10">96</div>
                    <div class="w10">94</div>
                    <div class="w10">93</div>
                    <div class="w10">91</div>
                    <div class="w10">89</div>
                    <div class="w10">87</div>
                    <div class="w10">85</div>
                </div>
                <div class="disflex">
                    <div class="w10">&#12288;</div>
                    <div class="w10"><label><input name="choose_2" value="50-100" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="54-98" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="59-96" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="63-94" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="68-93" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="72-91" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="76-89" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="81-87" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_2" value="85-85" type="radio"/></label></div>
                </div>
            </div>
            <div class="box_card textc">
                <span class="num">(4)</span>
                <div class="disflex">
                    <div class="w11">你得到</div>
                    <div class="w10">50</div>
                    <div class="w10">54</div>
                    <div class="w10">59</div>
                    <div class="w10">63</div>
                    <div class="w10">68</div>
                    <div class="w10">72</div>
                    <div class="w10">76</div>
                    <div class="w10">81</div>
                    <div class="w10">85</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w11">TA得到</div>
                    <div class="w10">100</div>
                    <div class="w10">89</div>
                    <div class="w10">79</div>
                    <div class="w10">68</div>
                    <div class="w10">58</div>
                    <div class="w10">47</div>
                    <div class="w10">36</div>
                    <div class="w10">26</div>
                    <div class="w10">15</div>
                </div>
                <div class="disflex">
                    <div class="w10">&#12288;</div>
                    <div class="w10"><label><input name="choose_3" value="50-100" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="54-89" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="59-79" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="63-68" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="68-58" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="72-47" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="76-36" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="81-26" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_3" value="85-15" type="radio"/></label></div>
                </div>
            </div>
            <div class="box_card textc">
                <span class="num">(5)</span>
                <div class="disflex">
                    <div class="w11">你得到</div>
                    <div class="w10">100</div>
                    <div class="w10">94</div>
                    <div class="w10">88</div>
                    <div class="w10">81</div>
                    <div class="w10">75</div>
                    <div class="w10">69</div>
                    <div class="w10">63</div>
                    <div class="w10">56</div>
                    <div class="w10">50</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w11">TA得到</div>
                    <div class="w10">50</div>
                    <div class="w10">56</div>
                    <div class="w10">63</div>
                    <div class="w10">69</div>
                    <div class="w10">75</div>
                    <div class="w10">81</div>
                    <div class="w10">88</div>
                    <div class="w10">94</div>
                    <div class="w10">100</div>
                </div>
                <div class="disflex">
                    <div class="w10">&#12288;</div>
                    <div class="w10"><label><input name="choose_4" value="100-50" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="94-56" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="88-63" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="81-69" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="75-75" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="69-81" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="63-88" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="56-94" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_4" value="50-100" type="radio"/></label></div>
                </div>
            </div>
            <div class="box_card textc">
                <span class="num">(6)</span>
                <div class="disflex">
                    <div class="w11">你得到</div>
                    <div class="w10">100</div>
                    <div class="w10">98</div>
                    <div class="w10">96</div>
                    <div class="w10">94</div>
                    <div class="w10">93</div>
                    <div class="w10">91</div>
                    <div class="w10">89</div>
                    <div class="w10">87</div>
                    <div class="w10">85</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                    <div class="w10">|</div>
                </div>
                <div class="disflex">
                    <div class="w11">TA得到</div>
                    <div class="w10">50</div>
                    <div class="w10">54</div>
                    <div class="w10">59</div>
                    <div class="w10">63</div>
                    <div class="w10">68</div>
                    <div class="w10">72</div>
                    <div class="w10">76</div>
                    <div class="w10">81</div>
                    <div class="w10">85</div>
                </div>
                <div class="disflex">
                    <div class="w10">&#12288;</div>
                    <div class="w10"><label><input name="choose_5" value="100-50" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="98-54" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="96-59" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="94-63" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="93-68" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="91-72" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="89-76" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="87-81" type="radio"/></label></div>
                    <div class="w10"><label><input name="choose_5" value="85-85" type="radio"/></label></div>
                </div>
            </div>
        </main>
        <div class="partition"></div>
        <footer class="footer">
            <button class="btn btn-dark" @click="publishAnswerList">提交问卷</button>
        </footer>
    </div>
</template>

<script>
    import {startQuestion, publishAnswerList} from "@/api/ggwpby/question";
    import {getUserKey} from "@/utils/auth";

    export default {
        name: "index",
        data() {
            return {
                resultId: null,
                roomId: null,
                answerList: []
            }
        },
        mounted() {
            this.startQuestion()
        },
        methods: {
            startQuestion() {
                startQuestion().then((res) => {
                    this.roomId = res.roomId
                    this.resultId = res.data
                }).catch(() => {
                    this.$router.push("/common/matching/" + getUserKey())
                })
            },
            // 提交问卷答案
            publishAnswerList() {
                let answer = {}
                for (let i = 0; i < 6; i++) {
                    let chooses = document.getElementsByName('choose_' + i)
                    let val;
                    for (let j = 0; j < chooses.length; j++) {
                        if (chooses[j].checked) {
                            val = chooses[j].value;
                            // 拆分出你得到的和TA得到的数据
                            let strings = val.split("-");
                            answer["item" + (i + 1) + "Ps"] = strings[0]
                            answer["item" + (i + 1) + "Po"] = strings[1]
                        }
                    }
                    if (!val) {
                        this.$modal.msgClose();
                        this.$modal.msgError("第" + (i + 1) + "题未选择");
                        return;
                    }
                }
                console.log(answer, this.resultId, this.roomId)
                // 提交答案
                publishAnswerList({
                    answer: JSON.stringify(answer),
                    resultId: this.resultId,
                    roomId: this.roomId
                }).then(res => {
                    if (res.code === 0) {
                        this.$modal.loading("数据提交中");
                        setTimeout(() => {
                            this.$modal.closeLoading();
                            this.$router.push("/common/tasklist/" + getUserKey())
                        }, 1000);
                    } else {
                        this.$modal.alertError(res.msg);
                    }
                })
            }
        }
    }
</script>

<style scoped>
    * {
        padding: 0;
        margin: 0;
        font-size: 18px;
    }

    .self_select_box {
        /* position: relative; */
        width: 150px;
    }

    .textc {
        text-align: center;
    }

    .bord_solid {
        border: 1px solid #000;
        padding: 5px;
    }

    header {
        width: 100%;
        height: 10px;
        display: flex;
    }

    header div {
        width: 25%;
    }

    .w25 {
        width: 25%;
    }

    .w10 {
        width: 10%;
    }

    .w11 {
        width: 11%;
    }

    main {
        padding: 20px;
    }

    .disflex {
        display: flex;
        margin-left: 34px
    }

    .partition {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 2px solid #f1f1f2;
        background-color: #f1f1f2;
    }

    .box_card {
        padding: 10px;
        letter-spacing: 3px;
        width: 60%;
        position: relative;
        left: 50%;
        margin-left: -30%;
        margin-bottom: 30px;
        /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    }

    .line-height {
        line-height: 40px;
    }

    .line-height p {
        text-indent: 2em;
    }

    .box_card_2 {
        padding: 10px;
        /*line-height: 40px;*/
        width: 60%;
        position: relative;
        left: 50%;
        margin-left: -30%;
        margin-bottom: 30px;
    }

    .mainText {
        color: red;
        font-weight: bold;
    }

    .Lh40 {
        line-height: 90px;
    }

    .num {
        float: left;
    }

    .footer {
        text-align: center;
        height: 60px;
        line-height: 30px;
    }

    .btn-dark {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40
    }

    .btn-dark:hover {
        color: #fff;
        background-color: #23272b;
        border-color: #1d2124
    }

    .btn-dark.focus, .btn-dark:focus {
        color: #fff;
        background-color: #23272b;
        border-color: #1d2124
    }

    .btn-dark.disabled, .btn-dark:disabled {
        color: #fff;
        background-color: #343a40;
        border-color: #343a40
    }

    .btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
        color: #fff;
        background-color: #1d2124;
        border-color: #171a1d
    }

    .btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
    }

    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px
    }
</style>
