<template>
    <div>
        <br/><br/><br/>
        <el-row class="center">
            <el-col :offset="6" :span="12">
                <h2 class="fons22">第<span class="colorf00 fons22">{{ roundSizeTemp }}</span>轮</h2>
                <p class="fons18">在本轮游戏中，你认为<u>小组内其他<span class="colorf00 fons18">{{ oppoUserCount }}</span>名玩家</u><span class="colorf00 fons18">每人平均</span>投放的代币数为多少？
                </p>
                <p class="fons18">请输入<span class="colorf00 fons18">0到{{initialCoinCountTemp}}</span>的整数：</p>
            </el-col>
            <el-col :span="24" class="Textc fontwb" style="font-size: 22px; margin-top: 20px;  letter-spacing: 5px;">
                <!--<el-input-number class="" v-model="investorNumberGuess" :max="initialCoinCountTemp" :min="0"
                                 :precision="0" :step="1"></el-input-number>-->
                <el-input-number
                          v-model.number="investorNumberGuess"
                          :min="0"
                          :max="initialCoinCountTemp"
                          @input.native="onInput0_max"
                          onKeypress="return (/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
                       ></el-input-number>
            </el-col>
        </el-row>

        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'

    const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('ggwpby')
    import Footer from "@/views/ggwpby/components/Footer"

    export default {
        components: {Footer},
        data() {
            return {
                investorNumberGuess: undefined,
                // investorNumberGuess: 2,
                roundSizeTemp: 1,
                initialCoinCountTemp: 10,
                oppoUserCount: 3,
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'dataList', 'ggwpbyRefereeDto', 'dataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            },
        },
        mounted() {
            this.load();
            this.pushData();

            //自动测试
            if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            ...mapGetters(['getPage']),
            ...mapMutations(['setDataJson']),
            ...mapActions(['pushDataList']),

            onInput0_max(e) {
                let max = this.initialCoinCountTemp;
                //关闭提示
                this.$modal.msgClose();
                let value = e.target.value;
                if (!value.match(/^\d{1,3}(\.\d*)?$/)) {
                    value = 0
                }
                value = Number(value)
                if (value < 0 || value > max) {
                    this.$modal.msgWarning("请输入0到" + max + "的整数！");
                }
                e.target.value = value < 0 ? 0 : value;
                e.target.value = value > max ? max : value;
                this.investorNumberGuess = e.target.value;
            },
            // 页面加载执行的方法
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                let page = this.getPage();
                this.html = page.dataJson;
                this.$store.dispatch("sleepTime");
                // this.dataJsonTemp = this.stateDataJson;
            },
            //数据填充
            pushData() {
                this.roundSizeTemp = this.ggwpbyRefereeDto.roundSize + 1;
                this.initialCoinCountTemp = this.ggwpbyRefereeDto.ggwpbyRandomDto.initialCoinCount;
                this.oppoUserCount = this.ggwpbyRefereeDto.ggwpbyErtRuleParamJsonDto.oppoUserCount;
            },
            // 继续按钮
            next() {
                this.$store.dispatch("butDisable");

                if (this.investorNumberGuess === undefined || this.investorNumberGuess === '') {
                    this.$modal.msgClose();
                    this.$modal.msgError("你认为小组内其他" + this.oppoUserCount + "名玩家每人平均投放的代币数为多少？");

                    return;
                }

                // 预测对手的投资数
                this.dataJsonTemp["investorNumberGuess"] = this.investorNumberGuess;
                this.dataJsonTemp['clickTime'] = this.nowTime;
                // 将数据存储到全局变量dataJson中
                this.setDataJson(this.dataJsonTemp);

                this.$emit("getNextPage", this.dataJson);

                // this.pushDataList(this.dataJson)
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                this.investorNumberGuess = Math.round(Math.random() * Number(this.initialCoinCountTemp));
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .center {
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    .fons22 {
        font-size: 22px;
    }

    .fons18 {
        font-size: 18px;
    }

    .colore99 {
        color: #e99313;
    }
    .colorf00 {
        color: #ff0000;
    }
</style>
