<template>
    <div>
        <Common>
            <router-view @toNextPage="toNextRouter" @getNextPage="getNextPage" @endErt="endErt"
                         @commitData="commitData"/>
        </Common>
        <Footer/>
        <SocketClinet :resultId="ggwpbyResult.id" :roomId="ggwpbyResult.roomId"/>
    </div>
</template>

<script>
    import Common from "../common"
    import Footer from "@/components/common/footer";
    import SocketClinet from "@/components/socket";

    import {createNamespacedHelpers} from 'vuex'
    import {setExpKey, getUserKey} from "@/utils/auth";
    import {fullScreen} from "@/utils/FullScreen";

    const {mapState, mapMutations, mapActions, mapGetters} = createNamespacedHelpers('ggwpby')
    // import {backSpace} from "@/utils/refresh";

    export default {
        components: {Footer, Common, SocketClinet},
        name: "index",
        data() {
            return {}
        },
        computed: {
            ...mapState(['config',
                "dataList",
                "ggwpbyResult",
                "syncDataJson",
                "ggwpbyRefereeDto"
            ]),
        },
        mounted() {
            setExpKey(this.$store.state.ggwpby.expKey)
            // 全屏打开
            if (this.config.openFullState) this.openFullScreen();
            // 初始化方法
            this.init();

            // this.get_cache_size('l')
            // this.get_cache_size('s')
        },
        methods: {
            ...mapGetters(['getPage']),
            ...mapMutations(['setPageInfo', 'ertEnd', 'nextResult']),
            ...mapActions(['initData']),
            // 是否全屏打开
            openFullScreen() {
                this.$modal.alertSuccess("为了您的实验体验，请点击确认开启全屏模式!", (action) => {
                    if (action === "confirm") {
                        fullScreen();
                    }
                });
            },
            // 跳转下一页面的路由方法
            toNextRouter() {
                let configPage = this.getPage();
                this.$router.push({path: configPage.tempPath + "/" + getUserKey(), replace: true})
            },
            // 调用后台方法-获取下一页数据
            getNextPage(dataJson) {
                let socket = this.$store.getters.getSocket;
                if (socket) {
                    let ggwpbyNextIndexPojo = {
                        resultId: this.ggwpbyResult.id,
                        pageIndex: this.$store.state.page.pageIndex,
                        progressIndex: this.$store.state.progress.progressIndex,
                        dataJsonStr: JSON.stringify(dataJson),
                        syncDataJsonStr: JSON.stringify({
                            "resultDataListStr": JSON.stringify(this.syncDataJson.resultDataList),
                            "resultDataNewListStr": JSON.stringify(this.syncDataJson.resultDataNewList),
                            "rewardUserName": this.syncDataJson.rewardUserName,
                            "belittleUserId": this.syncDataJson.belittleUserId
                        }),
                    }

                    socket.emit("ggwpby_index", ggwpbyNextIndexPojo, (res) => {
                        if (res.code === 0) {

                            // 准备跳下一页， pageIndex + 1
                            this.$store.commit("setPageIndex");
                            // 将后台的全局数据同步到前端
                            this.setPageInfo(res.data.pageInfo);
                            // 跳转下一页面的路由方法
                            this.toNextRouter()
                        }
                    })
                }
            },
            // 初始化方法
            init() {
                let socket = this.$store.getters.getSocket;
                if (socket) {
                    socket.emit("ggwpby_init", (res) => {
                        this.initData(res)
                        // 跳转下一页面的路由方法
                        // this.toNextRouter()
                    })
                }
            },
            // 上传数据
            commitData(success, roundEnd = false) {
                this.$modal.loading("请求中");
                // 结果数据
                let tempList = this.dataList.splice(0, this.dataList.length);
                if (this.config.logState) console.log("tempList", tempList)

                //发往后端@OnEvent("mouse")注解的方法
                let socket = this.$store.getters.getSocket;
                socket.emit("mouse", {
                    resultId: this.ggwpbyResult.id,
                    mouseList: this.$store.state.mouseList.splice(0, this.$store.state.mouseList.length)
                }, (res) => {
                })


                //发往后端@OnEvent("ggwpby_save")注解的方法
                socket.emit("ggwpby_save", {
                    ggwpbyResultId: this.ggwpbyResult.id,
                    dataStr: JSON.stringify(tempList),
                    progressIndex: this.$store.state.progress.progressIndex,
                    roundGroupSize: this.ggwpbyRefereeDto.roundGroupSize,
                    pageIndex: this.$store.state.page.pageIndex,
                    roundSize: this.ggwpbyRefereeDto.roundSize,
                    roundEnd: roundEnd
                }, (res) => {

                    if (res.code === 0) {
                        tempList = [];
                        success(res);
                    } else {
                        // 提交失败，数据返回给dataList
                        this.dataList.concat(tempList);
                        this.$modal.alertError(res.msg);
                    }
                    this.$modal.closeLoading();
                });
            },
            // 结束实验
            endErt(money) {
                //发往后端@OnEvent("ggwpby_end")注解的方法
                let socket = this.$store.getters.getSocket;
                socket.emit("ggwpby_end", {
                    money: money
                }, (res) => {
                    if (res.code === 0) {
                        this.$modal.loading("数据提交中，请勿刷新！");
                        // this.ertEnd(0);

                        this.$store.state.page.pageCount = 0;
                        this.$store.state.page.pageIndex = 0;
                        this.$store.state.progress.progressIndex = 0;

                        this.$store.commit("destroySocket");

                        this.$modal.closeLoading();
                        this.$router.push("/common/tasklist/" + getUserKey())
                    }
                });
            },
            // 获取缓存数据存储大小
            get_cache_size(t) {
                t = t === undefined ? "l" : t;
                let obj = "";
                if (t === 'l') {
                    if (!window.localStorage) {
                        console.log('浏览器不支持localStorage');
                    } else {
                        obj = window.localStorage;
                    }
                } else {
                    if (!window.sessionStorage) {
                        console.log('浏览器不支持sessionStorage');
                    } else {
                        obj = window.sessionStorage;
                    }
                }
                if (obj !== "") {
                    let size = 0;
                    for (let item in obj) {
                        if (obj.hasOwnProperty(item)) {
                            size += obj.getItem(item).length;
                        }
                    }
                    console.log('当前已用存储：' + (size / 1024).toFixed(2) + 'KB');
                }
            }
        },
        beforeDestroy() {
            this.$modal.closeLoading();
            this.$store.commit("destroySocket");
        },
    }
</script>
<style lang="scss" scoped>
    //::v-deep .el-main {
    //  height: 88.9vh;
    //}
</style>

