<template>
    <div>
        <el-row style="display: flex; justify-content: space-around;">
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">(你)本轮收益：</div>
                <div class="tabclass_db">{{ roundProfitAll }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮从公共账户中获益：</div>
                <div class="tabclass_db">{{ roundProfit }}</div>
            </div>
            <div class="tabclass">
                <div class="tabclass_text bgc03628e">本轮个人账户剩余：</div>
                <div class="tabclass_db">{{ noInvestorNumber }}</div>
            </div>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content Textc"><p>所有玩家选择完毕！</p>
                    <p>每名玩家可从本轮公共投资中获得：<span class="fontRed">{{roundProfit}}</span>个代币</p>
                    <p>本轮小组成员向公共账户投放代币的情况以及对应的星级信息如下：</p></div>
            </el-col>
        </el-row>
        <el-row style="display: flex; justify-content: space-around;">
            <el-col :span="24" class="Star" style="width: 400px">
                <el-row class="header">
                    <el-col :span="6">星级排名</el-col>
                    <el-col :span="6">代号</el-col>
                    <el-col :span="6">星级数量</el-col>
                    <el-col :span="6">投放代币数</el-col>
                </el-row>
                <el-col :span="24" class="main">
                    <el-row v-for="(item, index) in rankList" :key="index" style="text-align: center">
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.ranking }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.userGameName }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.starsCount }}</el-col>
                        <el-col :span="6" :style="{ color: styleFunc(item)}">{{ item.investorNumber }}个代币</el-col>
                    </el-row>
                </el-col>
            </el-col>
        </el-row>
        <br/>
        <br/>
        <el-row>
            <el-col :span="24" class="Textc  fons22">
                目前你本轮的星级为{{starsTemp}}颗星。现在你可以通过向系统支付代币提升自己的星级。
            </el-col>
            <el-col :span="24" class="Textc  fons22">
                （<span class="TextColorE99313">1代币</span>提升<span class="TextColorE99313">{{starsRatioTemp}}颗星</span>，最多可提升至<span
                class="TextColorE99313">{{promoteUpperLimitTemp}}颗星</span>）
            </el-col>
            <el-col :span="24" class="Textc  fons22">
                你是否想要提升自己的星级？
            </el-col>
            <el-col :span="24" style="font-size: 18px; margin-top: 20px; text-align: center">
                <el-button class="fontwb" :type="beNoPromote? 'primary' : 'text'"
                           @click="beNoPromote = true; investorPromoteNumberAll = starsTemp">{{
                    $t('situation.btn3') }}
                </el-button>
                <el-button class="fontwb  ml100" :type="!beNoPromote && beNoPromote != null ? 'primary' : 'text'"
                           @click="beNoPromote = false; promote = null">{{
                    $t('situation.btn4') }}
                </el-button>
            </el-col>
        </el-row>
        <el-row v-if="beNoPromote" class="Textc">
            <el-col :span="24" class=" fons22">
                请输入你想要提升的星级数量（大于等于零的整数）：
            </el-col>
            <el-col :span="24" class=" fons22">
<!--                <el-input-number class="" v-model="promote" :max="promoteCountMaxTemp" :min="0"-->
<!--                                 :precision="0" :step="1" @change="promoteStars"></el-input-number>-->
                <el-input-number
                          v-model.number="promote"
                          :min="0"
                          :max="promoteCountMaxTemp"
                          @change="promoteStars"
                          @input.native="onInput0_max"
                          onKeypress="return (/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
                       ></el-input-number>
            </el-col>
            <el-col :span="24" class=" fons22">
                （提升后星级为{{ investorPromoteNumberAll }}颗星，你需要支付{{ investorPromote }}个代币）
            </el-col>
        </el-row>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer>
            <el-button class="fontwb" @click="next" type="primary"
                       :disabled="$store.state.sleep.currentTime > 0 || $store.state.butDisabled.clickCountDown > 0">
                <span>
                    {{ $t('situation.btn5') }}
                    <span v-if="$store.state.sleep.currentTime > 0">({{ $store.state.sleep.currentTime }}秒)</span>
                </span>
            </el-button>
        </Footer>
    </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'
    import {styleFunc} from "@/utils/result";
    import Footer from "@/views/ggwpby/components/Footer";

    const {mapMutations, mapGetters, mapState, mapActions} = createNamespacedHelpers('ggwpby')

    export default {
        components: {Footer},
        data() {
            return {
                radio: '',
                roundProfitAll: 0,
                roundProfit: 0,
                noInvestorNumber: 0,
                starsRatioTemp: 0,
                promote: null,
                beNoPromote: null,
                promoteUpperLimitTemp: 10,
                promoteCountMaxTemp: 10,
                starsRatioSplit: [],
                starsTemp: 0,
                investorPromote: 0,
                investorPromoteNumber: 0,
                investorPromoteNumberAll: 0,
                rankList: [
                    {ranking: 1, userGameName: '玩家A', starsCount: 8, investorNumber: 10},
                    {ranking: 2, userGameName: '玩家C', starsCount: 4, investorNumber: 8},
                    {ranking: 3, userGameName: '你（玩家B）', starsCount: 5, investorNumber: 8},
                    {ranking: 4, userGameName: '玩家D', starsCount: 3, investorNumber: 4},
                ],
                dataJsonTemp: {}
            };
        },
        computed: {
            ...mapState(['config', 'ggwpbyResult', 'ggwpbyRefereeDto', 'ggwpbyErtRuleParamJsonDto', 'dataJson', 'syncDataJson']),
            nowTime() {
                return this.$store.getters.getNowTime;
            }
        },
        mounted() {
            this.load();

            //自动测试
            // if (this.config.autoClickState) this.autoClickFun();
        },
        methods: {
            styleFunc,
            ...mapActions(['pushDataList']),
            ...mapMutations(['setDataJson']),

            onInput0_max(e) {
                let max = this.promoteCountMaxTemp;
                //关闭提示
                this.$modal.msgClose();
                let value = e.target.value;
                if (!value.match(/^\d{1,3}(\.\d*)?$/)) {
                    value = 0
                }
                value = Number(value)
                if (value < 0 || value > max) {
                    this.$modal.msgWarning("请输入0到" + max + "的整数！");
                }
                e.target.value = value < 0 ? 0 : value;
                e.target.value = value > max ? max : value;
                this.promote = e.target.value;
                this.promoteStars();
            },
            load() {
                this.dataJsonTemp['loadTime'] = this.nowTime;
                this.rankList = this.syncDataJson.resultDataList;
                this.noInvestorNumber = this.dataJson.noInvestorNumber;
                this.roundProfitAll = this.dataJson.roundProfitAll;
                this.roundProfit = this.dataJson.roundProfit;
                this.promoteUpperLimitTemp = this.ggwpbyErtRuleParamJsonDto.promoteUpperLimit;
                this.rankList.forEach(rank => {
                    if (rank.userGameName.indexOf(this.ggwpbyResult.userGameName) !== -1) {
                        this.starsTemp = rank.starsCount;
                    }
                });

                //代币转换星级比例
                let starsRatio = this.ggwpbyErtRuleParamJsonDto.starsRatio;
                if (starsRatio) {
                    let starsRatioSplitTemp = starsRatio.split(",");
                    if (starsRatioSplitTemp.length === 2) {
                        this.starsRatioSplit = starsRatioSplitTemp;
                        this.starsRatioTemp = this.starsRatioSplit[0];
                    }else{
                        if (starsRatioSplitTemp.length === 1){
                            this.starsRatioSplit = [starsRatioSplitTemp[0], 0]
                        }else{
                            this.starsRatioSplit = [1, 0]
                        }
                    }
                }

                //最高可提升代币
                let promoteCountMax = (this.promoteUpperLimitTemp - this.dataJson.investorNumber) / this.ggwpbyErtRuleParamJsonDto.transactionRatio;
                //不可超过可使用代币
                this.promoteCountMaxTemp = promoteCountMax > this.dataJson.roundProfitAll ? this.dataJson.roundProfitAll : promoteCountMax;

                this.$store.dispatch("sleepTime");
            },
            promoteStars() {
                let stars = Number(this.dataJson.investorNumber * this.starsRatioSplit[0]).toFixed(this.starsRatioSplit[1]);
                // this.ggwpbyErtRuleParamJsonDto.transactionRatio;
                this.investorPromote = this.promote / this.starsRatioSplit[0];
                this.investorPromoteNumber = Number(this.promote * Number(this.starsRatioSplit[0]).toFixed(this.starsRatioSplit[1]));
                this.investorPromoteNumberAll = Number(stars) + this.investorPromoteNumber
            },
            next() {
                this.$modal.msgClose();
                this.$store.dispatch("butDisable");
                if (this.beNoPromote == null) {
                    this.$modal.msgWarning("请输入是否需要提升星级！");
                    return;
                }

                if (this.promote === 0) {
                    this.$modal.msgWarning("请输入需要提升的星级数量！");
                    return;
                }

                this.dataJsonTemp['clickTime'] = this.nowTime;

                this.dataJsonTemp["beNoPromote"] = this.beNoPromote;
                this.dataJsonTemp["investorPromote"] = this.investorPromote;
                this.dataJsonTemp["investorPromoteNumber"] = this.investorPromoteNumber;
                this.dataJsonTemp["investorPromoteNumberAll"] = this.investorPromoteNumberAll;

                this.dataJsonTemp["roundProfit"] = Number(this.dataJson.roundProfit);
                this.dataJsonTemp["roundProfitAll"] = (Number(this.dataJson.roundProfitAll) - Number(this.investorPromote)).toFixed(2);
                this.dataJsonTemp["roundGroupProfit"] = (Number(this.dataJson.roundGroupProfit) - Number(this.investorPromote)).toFixed(2);
                this.dataJsonTemp["wallet"] = (Number(this.dataJson.wallet) - Number(this.investorPromote)).toFixed(2);
                // 将数据存储到全局变量dataJson中
                this.setDataJson(this.dataJsonTemp);

                this.$emit("getNextPage", this.dataJson);
            },

            /**
             * 自动测试
             */
            autoClickFun() {
                this.beNoPromote = Math.round(Math.random() * Number(1)) > 0;
                if (this.beNoPromote) {
                    this.promote = Math.floor(Math.random() * Number(this.promoteCountMaxTemp) + 1);
                    this.promoteStars();
                }
                setTimeout(() => {
                    this.next();
                }, 1000 * this.config.autoClickTime);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .el-button span {
        height: 100%;
        width: 100%;
    }

    .ml100 {
        margin-left: 100px;
    }

    .header {
        text-align: center;
    }

    // 按钮激活状态
    .beNoPromote {
        border-color: var(--buttonColor);
        background-color: var(--buttonColor);
    }

    .fons22 {
        font-size: 22px;
        margin-top: 20px;
        letter-spacing: 5px;
    }

    .grid-content {
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 40px;
        font-size: 18px;
    }
</style>
